<template>
    <v-app>
        <v-card-title>
            <span class="headline font-weight-bold text-uppercase grey--text">{{title}}</span>
        </v-card-title>
                    <v-card color="#FCFCFC" class="mb-12" height="300px">
                        <v-container>
                            <span class="title font-weight-bold text-uppercase grey--text">Basic Information</span>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <v-text-field v-model="name" label="Name"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <v-select
                                            v-model="benefitTypeSelected"
                                            :items="benefitTypes"
                                            item-text="name"
                                            item-value="id"
                                            label="Benefit Type"
                                            return-object
                                            single-line
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <v-select
                                            v-model="modeValue"
                                            :items="modes"
                                            label="Assigment Mode"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn text @click='cancel(false)'>Cancel</v-btn>
                                <v-btn color="#eabd75" @click="save()">
                                    Save
                                    <v-icon> mdi-content-save-settings</v-icon>
                                </v-btn>
                            </v-row>
                            <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
                                {{ snackbartext }}
                                <v-btn dark text @click="snackbar = false">
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-snackbar>
                        </v-container>
                    </v-card>

    </v-app>
</template>
<script>
    export default {
        name: "ManageBenefits",
        props:['benefitId'],

        data: () => ({
            snackbar: false,
            snackbartext: '',
            snackbarcolor: '',
            snack: false,
            snackColor: '',
            snackText: '',
            name:'',
            benefitTypes:[],
            modes: [
                {text:"Automatic", value:"Automatic"},
                {text:"Manual", value:'Manual'}
            ],
            modeValue: null,
            benefitTypeSelected: { name: '', id: 0 },

            title: 'New Benefit',
        }),
        mounted() {
            axios.get('/api/benefits_types').then(res => {
                this.benefitTypes=res.data.benefitTypes;
            });
            if(this.benefitId === undefined) {

            }else{
                this.title = 'Edit Benefit';
                    axios.get('/api/benefits/'+this.benefitId).then(res => {
                    this.name=res.data.benefit.name;
                    this.benefitTypeSelected={name: res.data.benefit.benefit_type.name, id : res.data.benefit.benefit_type.id};
                });
            }

        },
        methods:{
            save() {
                if(this.benefitId === undefined) {
                    axios.post('/api/benefits', {'name': this.name, 
                            'benefit_type_id': this.benefitTypeSelected.id,
                            'mode': this.modeValue})
                        .then(response => {
                            let res = response;
                            this.activateSnackbar(`You have successfully created the name ${res.data.benefit.name}`, 'success')
                            this.$router.push(`/benefits`)
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`error`, 'error')
                        });
                }else{
                    axios.put('/api/benefits/' + this.benefitId, {'name': this.name, 'benefit_type_id': this.benefitTypeSelected.id})
                        .then(response => {
                            let res = response;
                            this.activateSnackbar(`You have successfully edited the name ${res.data.benefit.name}`, 'success')
                            this.cancel(false);
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`error`, 'error')
                        });
                }

            },
            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },

            cancel (v) {
                if(this.benefitId === undefined) {
                    this.$router.push(`/benefits`);
                }else{
                    this.$emit('closeModal', v)
                }
            }
        },
    }
</script>

<style scoped>

</style>
