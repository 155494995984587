<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-btn class='GrofUSA mb-3' color="#eabd75" dark fab hover small :to="`/managebenefits`">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                            v-model="search"
                            label="Search"
                            single-line
                            hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers"
                      show-expand
                      :items="benefits"
                      item-key="name"
                      :search="search"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait">
            <template v-slot:item.plans="{ item, value}">
                <span>{{ item.name }}</span>
            </template>
            <template v-slot:expanded-item="{ item }">
                    <td colspan="4" style="padding: 40px">
                        <v-card
                                class="mx-auto"
                                max-width="344"
                                outlined
                        >
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-center">Plans</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="benefit_plan in item.plans">
                                        <td>{{ benefit_plan.plan.name }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </td>
            </template>
            <template v-slot:top>
                <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                        <manage-benefits class="p-4" @closeModal="closeModal($event)" :benefit-id="benefitId"></manage-benefits>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="notification" max-width="500px">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-col cols="12">
                                    <span class="subtitle-1">Are you sure you want to delete this benefit?</span>
                                </v-col>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="deleteItem">Accept</v-btn>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon small class="mr-2" v-on:click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                     <v-icon small v-on:click="openNotification(item)">
                        mdi-delete
                    </v-icon>
                </td>
            </template>
        </v-data-table>
        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
            {{ snackbartext }}
            <v-btn  dark text @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-app>
</template>

<script>
    import ManageBenefits from "../Benefits/ManageBenefits";
    export default {
        name: "Dashboard",
        components: {ManageBenefits},

        data() {
            return {
                dialog: false,
                notification: false,
                benefitId: -1,
                index: -1,
                deletedItemId: -1,
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
                search: '',
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: 'Benefit type', value: 'type'},
                    {text: 'Assignment Mode', value: 'mode'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                benefits: [],
                loading: true,
            }
        },

        computed: {
        },

        mounted() {
            axios.get('/api/benefits').then(res => {
                this.loading = false;
                this.benefits = res.data.benefits;
            });
        },

        methods:{
            editItem: function (item) {
                this.dialog = true
                this.benefitId = item.id;
            },
            deleteItem: function(){
                this.benefits.splice(this.index, 1);
                axios.delete('/api/benefits/' + this.deletedItemId)
                    .then(response => {
                        let res = response;
                        this.activateSnackbar(`You have successfully deleted the benefit`, 'success')
                    })
                    .catch(error=> {
                        console.log(error);
                        this.activateSnackbar(`Error`, 'error')
                    });
                this.close();
            },

            close () {
                this.dialog = false;
                this.benefitId = -1;
                this.notification=false;
                this.index = -1;
                this.deletedItemId = -1;
            },
            closeModal (value) {
                this.dialog = value;
                axios.get('/api/benefits').then(res => {
                    this.benefits = res.data.benefits;
                });
            },

            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },
            openNotification: function (item) {
                this.notification = true;
                this.index = this.benefits.indexOf(item);
                this.deletedItemId = item.id;
            },
        },
    };
</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }

    .container{
        max-width: 100vw;
    }
</style>



