import Vue from 'vue'
import Router from 'vue-router'

import Home from "./views/General/Home";
import Configuration from './views/auth/configuration';
import Login from "./views/auth/login";
import Plans from "./views/Plans/Dashboard";
import Users from "./views/Users/Dashboard";
import Benefits from "./views/Benefits/Dashboard";
import Error from "./views/General/404";
import Companies from "./views/Users/Companies";
import PersonalUsers from "./views/Users/PersonalAccounts";
import UsersDetails from "./views/Users/Users";
import ManagePlans from "./views/Plans/ManagePlans";
import InfoPlan from "./views/Plans/InfoPlan";
import ManageBenefits from "./views/Benefits/ManageBenefits";
import ManageBrands from "./views/Brands/ManageBrands";
import Brands from "./views/Brands/Dashboard";
import Transactions from "./views/Transactions/Dashboard";
import TransactionsByShop from "./views/Transactions/TransactionsByShop";
import Products from "./views/Products/Dashboard";
import PriceUpdate from "./views/Products/PriceUpdate";
import ManageProducts from "./views/Products/ManageProducts";
import ImportProducts from "./views/Products/ImportProducts";
import RedeemingSales from "./views/Sales/RedeemingSales";
import RedeemingSalesDetails from "./views/Sales/RedeemingSalesDetails";
import PriceCategories from "./views/PriceCategories/Dashboard";
import Returns from "./views/SaleReturns/Dashboard";

Vue.use(Router)

export default new Router({
    routes:[
        {
            path: '/',
            name:'Home',
            component: Home,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/configuration',
            name:'Configuration',
            component: Configuration,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/login',
            name:'Login',
            component: Login
        },
        {
            path: '/plans',
            name: 'Plans',
            component: Plans,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/manageplans',
            name: 'New Plans',
            component: ManagePlans,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/manageplans/edit/:id',
            name: 'Edit Plans',
            component: ManagePlans,
            props: true,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/pricecategories',
            name: 'Price Categories',
            component: PriceCategories,
            props: true,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/infoplan/:id',
            name: 'Info Plan',
            component: InfoPlan,
            props: true,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/benefits',
            name: 'Benefits',
            component: Benefits,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/managebenefits',
            name: 'Benefit',
            component: ManageBenefits,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/users',
            name: 'Professional Accounts',
            component: Users,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/personals',
            name: 'Personals Accounts',
            component: PersonalUsers,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path:'/users/:id',
            name: 'Accounts Details',
            component: UsersDetails,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path:'/companies/:id',
            name: 'Company Details',
            component: Companies,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path:'/brands',
            name: 'Brands',
            component: Brands,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/managebrands',
            name: 'ManageBrands',
            component: ManageBrands,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path:'/transactions',
            name: 'Transactions',
            component: Transactions,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/transactionsByShop/:id',
            name: 'Transactions Shop',
            component: TransactionsByShop,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path:'/products',
            name: 'Products',
            component: Products,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path:'/returns',
            name: 'Returns',
            component: Returns,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/products/import',
            name: 'Import Product',
            component: ImportProducts,
        },
        {
            path: '/products/price',
            name: 'Price Update',
            component: PriceUpdate,
        },
        {
            path: '/manageproducts',
            name: 'New Product',
            component: ManageProducts,
        },
        {
            path: '/manageproducts/edit/:id',
            name: 'Edit Product',
            component: ManageProducts,
            props: true
        },
        {
            path:'/redeeming',
            name: 'Redeeming Sales',
            component: RedeemingSales,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path:'/redeeming/:id',
            name: 'Redeeming Sales Details',
            component: RedeemingSalesDetails,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '*',
            component: Error
        }
    ],
    mode: 'history',
});


