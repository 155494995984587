<template>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.first_name" label="First Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.last_name" label="Last Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.jobtitle.name" label="Jobtitle"></v-text-field>
                </v-col>
            </v-row>
            <span class="title font-weight-bold text-uppercase grey--text">Address Information</span>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>Billing Address</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field label="Address 1"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field label="Address 2"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field label="City"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field label="State"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field label="Country"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field label="ZIP"></v-text-field>
                        </v-col>
                    </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>Shipping Address</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="Address 1"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="Address 2"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="City"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="State"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="Country"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="ZIP"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-container>
    </v-card-text>
</template>

<script>
    export default {
        name: "ContactInformationPopUp",
        props:['editedItem']
    }
</script>

<style scoped>

</style>
