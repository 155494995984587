<template>
    <v-app>

        <v-alert type="success"
        v-if="messageSuccess != ''"
        >
            {{ messageSuccess }}
        </v-alert>

        <v-alert type="error"
        v-if="messageError != ''"
        >
            {{ messageError }}
        </v-alert>

        <v-container>
            <v-row>
                <v-col class="d-flex" cols="12" sm="6" md="6">
                    <v-select
                        v-model="source"
                        :items="sourceList"
                        label="Shopify Source"
                    ></v-select>
                </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
        </v-container>

        <v-container>
            <v-row>
                <v-col class="d-flex" cols="12" sm="6" md="6">
                    <v-file-input
                        v-model="file"
                        small-chips
                        show-size
                        counter
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        label="Attach a formatted file xls, xlsx"
                        id="file"
                        ref="file"
                    ></v-file-input>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <div class="text-center">
                <v-btn color="#eabd75" dark class="mb-2" @click="loadFile()" :disabled="loadBtn">Load</v-btn>
                <v-btn color="blue-grey" dark class="mb-2" @click="resetImport()">Reset</v-btn>
                <v-btn color="grey" dark class="mb-2" @click='cancel()'>Cancel</v-btn>
            </div>
        </v-container>

        <v-container v-if="loading">
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
            >
                <v-col
                    class="subtitle-1 text-center"
                    cols="12"
                >
                    Loading... Please Wait and don't reload the page!
                </v-col>
                <v-col cols="6">
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>
            </v-row>
        </v-container>

    </v-app>
</template>

<script>
    export default {
        name: "PriceUpdate",

        data() {
            return {
                source:null,
                file: null,
                loading:false,
                loadBtn:false,
                messageSuccess:'',
                messageError:'',
                sourceList:[
                    {text: "Select", value: null},
                    {text: "GROF USA", value: "GROF"},
                    {text: "VIVES by GROF", value: "VIVES"},
                ],
            }
        },

        mounted() {

        },

        computed: {
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
        },

        watch: {

        },

        methods: {
            cancel(){
                this.$router.push('/products');
            },

            resetImport(){
                this.$nextTick(() => {
                    this.file = null;
                    this.messageError = '';
                });
            },

            loadFile(){
                this.loading = true;
                this.messageError = '';
                this.messageSuccess = '';
                this.loadBtn = true;
                let formData = new FormData();
                formData.append('file', this.file);
                if (this.source == null){
                    this.loading = false;
                    this.loadBtn = false;
                    return this.messageError = 'Please, you must select a Shopify';
                }
                formData.append('source', this.source);

                axios.post('/api/product/price/file',
                    formData,
                    {headers: {
                            //Authorization: 'Bearer ' + this.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(res => {
                    console.log(res);
                    this.loading = false;
                    this.messageSuccess = res.data.message;
                    setTimeout(() => { this.$router.push('/products'); }, 8000);
                    
                }).catch(error=> {
                    if (error.response && error.response.status === 422){
                        let e = error.response.data.error;
                        this.messageError = e;
                        this.loadBtn = false;
                        console.log(e);
                    }else if (error.response && error.response.status === 500){
                        let e = error.response.data.error;
                        this.messageError = e;
                        this.loadBtn = false;
                        console.log(e);
                        if (e === undefined ){
                            this.messageError = "Opss! Apparently the Excel file was not valid";
                        } 
                    }
                    this.loading = false;
                });
            },
        },
    }
</script>


<style scoped>
</style>