<template>
    <v-app>
        <v-content class="back">
            <v-container class="my-5">
                    <v-btn class='GrofUSA mb-3' color="#eabd75" dark fab hover small :to="`/manageplans`">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn class='GrofUSA mb-3' color="#eabd75" dark fab hover small @click='OpenConfiguration'>
                        <v-icon>mdi-cogs</v-icon>
                    </v-btn>
                <v-card flat class="plans" v-for="Plan in plans" :key=Plan.name>
                    <v-row class="pa-3">
                        <v-col cols="6">
                            <div class="caption grey--text"> Plan name</div>
                            <div>{{ Plan.name}}</div>
                        </v-col>
                        <v-col cols="2">
                            <div class="caption grey--text"> Monthly Price</div>
                            <div>${{ Plan.monthly_price}}</div>
                        </v-col>
                        <v-col cols="2">
                            <div class="caption grey--text"> Six Month Price</div>
                            <div>${{ Plan.six_months_price}}</div>
                        </v-col>
                        <v-col cols="2">
                            <v-row align="center" justify="center">
                                <v-speed-dial direction="right">
                                    <template v-slot:activator>
                                        <v-btn class='GrofUSA' color="#eabd75" dark fab hover small v-model="fab">
                                            <v-icon v-if="fab">mdi-close</v-icon>
                                            <v-icon v-else>mdi-chevron-double-right</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-btn fab dark small color="#eabd75" router :to="`/infoplan/${Plan.id}`" >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                    <v-btn fab dark small color="#eabd75" router :to="`/manageplans/edit/${Plan.id}`" >
                                        <v-icon>mdi-comment-edit</v-icon>
                                    </v-btn>
                                </v-speed-dial>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-card>
            </v-container>
        </v-content>
        <v-dialog
            v-model="rebase_configuration_flag"
            width="800"
        >
            <v-card>
                <v-card-title>
                   <span class="headline font-weight-bold text-uppercase grey--text"> % Over Sale/Purchase Configuration</span>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field v-model="rebase" label="% Over Sale/Purchase" :readonly='checkbox' :disabled='checkbox'></v-text-field>
                        </v-col>
                        
                        <v-checkbox
                            v-model="checkbox"
                            label="Activate Automatic Calculation"
                        ></v-checkbox>  
                    </v-row>   
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="CloseConfiguration">Close</v-btn>
                    <v-btn text @click="saveRebase">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>

    export default {
        name: "plansdashboard",
        data() {
            return {
                fab: false,
                plans: [],
                rebase: 0,
                rebase_configuration_flag: false,
                checkbox : false,
            }
        },
        mounted() {
            axios.get('/api/plans').then(res => {
                this.plans = res.data.plans;
                this.rebase = res.data.rebase.value;
                this.checkbox = res.data.rebase.isAutomatic;
            });
        },
        methods:{
            OpenConfiguration(){
                this.rebase_configuration_flag = true
            },
            CloseConfiguration(){
                this.rebase_configuration_flag = false
            },
            saveRebase(){
                axios.post('/api/rebase',{
                    'isAutomatic':this.checkbox,
                    'value':this.rebase
                }).then(res => {
                    this.rebase = res.data.rebase.value;
                    this.checkbox = res.data.rebase.isAutomatic;
                    this.CloseConfiguration();
                });
            }
        }

    }
</script>

<style>
    .plans{
        border-left: 4px solid #eabd75;
    }

    .back{
        z-index: 0;
    }

    .v-input__slot{
        margin-bottom: 0px !important
    }
</style>
