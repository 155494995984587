<template>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.billing_email" label="Email"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.website" label="Website"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.dba" label="DBA"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.ein" label="EIN"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" :md="editedItem.plans[0].plan_type == 'Regular' || editedItem.plans[0].plan_type == 'Trial'? 3 : 6">
                    <v-select
                        v-model="editedItem.status"
                        :items="status"
                        :rules="[v => !!v || 'Item is required']"
                        label="Status"
                        required
                    ></v-select>
                </v-col>
                <v-col v-if="editedItem.plans[0].plan_type == 'Regular' || editedItem.plans[0].plan_type == 'Trial'" cols="12" sm="12" md="3">
                    <v-select
                        v-model="editedItem.plans[0].id"
                        :items="planList"
                        :rules="[v => !!v || 'Item is required']"
                        label="Plan"
                        required
                    ></v-select>
                </v-col>
            </v-row>
            <span class="title font-weight-bold text-uppercase grey--text">Address Information</span>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>Billing Address</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="editedItem.billing_address.address1" label="Address 1"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="editedItem.billing_address.address2" label="Address 2"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="editedItem.billing_address.city.name" label="City"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="editedItem.billing_address.state.name" label="State"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="editedItem.billing_address.country.name" label="Country"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="editedItem.billing_address.post_code" label="ZIP"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>Shipping Address</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="local.shipping_address.address1" label="Address 1"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="local.shipping_address.address2" label="Address 2"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="City"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="State"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="Country"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field label="ZIP"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-container>
    </v-card-text>
</template>

<script>
    export default {
        name: "CompanyInformationPopUp",
        props:['editedItem', 'isCompany'],
        computed: {
            local() {
                return this.value ? this.value : { shipping_address: {} }
            },
        },
        data: () => ({
            status:['Pending','Active','Inactive'],
            plans:[],
            planList:[],
        }),
        mounted(){
            axios.get('/api/ndesignplans').then(res => {
                this.plans = res.data.plans;
                this.planList = [];
                for (var i = 0; i < this.plans.length; i++) {
                    this.planList.push({ text: this.plans[i].name, value: this.plans[i].id })
                }
            });
        }
    }
</script>

<style scoped>

</style>
