<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <div>
                    <v-btn fab dark small color="#eabd75" @click.stop="searchNewUsers">
                        <v-icon>mdi-cached</v-icon>
                    </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers"
                      show-expand
                      :items="users"
                      item-key="name"
                      :search="search"
                      :sort-by="['created_at']"
                      :sort-desc="[true]"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait">
            <template v-slot:item.created_at="{item, header, value}">
                <date-component :registration="value"></date-component>
            </template>
            <template v-slot:item.plans="{ item, value}">
                <span>{{ item.plans[0].name }}</span>
            </template>
            <template v-slot:item.status="{ item, value}">
                <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-simple-table class="test">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Jobtitle</th>
                                    <th>Available Points</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contact in item.contacts">
                                    <td><v-btn text class="text-none font-weight-regular" :to="`/users/${contact.id}`">{{ contact.first_name }} {{contact.last_name}}</v-btn></td>
                                    <td>{{ contact.email }}</td>
                                    <td>{{ contact.jobtitle !== null ? contact.jobtitle.name: ''  }}</td>
                                    <td>{{ contact.points_available}}</td>
                                    <td>
                                        <v-icon small class="mr-2" v-on:click="editItem(contact, false)">
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon small v-on:click="openNotification(contact, false, item)">
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </td>
            </template>
            <template v-slot:top>
                <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <span class="headline font-weight-bold text-uppercase grey--text">{{ formTitle }}</span>
                        </v-card-title>

                        <company-information-pop-up v-if='isCompany' :edited-item="editedCompany" :is-company="true"></company-information-pop-up>
                        <contact-information-pop-up v-else :edited-item="editedContact" :is-company="false"></contact-information-pop-up>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="notification" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline font-weight-bold text-uppercase grey--text">Alert</span>
                        </v-card-title>

                        <v-card-text v-if='isCompany'>
                            <v-container>
                                <v-col cols="12">
                                    <span class="subtitle-1">Are you sure you want to delete this company?</span>
                                </v-col>
                            </v-container>
                        </v-card-text>
                        <v-card-text v-else>
                            <v-container>
                                <v-col cols="12">
                                    <span class="subtitle-1">Are you sure you want to delete this user?</span>
                                </v-col>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="deleteitem">Accept</v-btn>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon small class="mr-2" v-on:click="editItem(item,true)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small v-on:click="openNotification(item, true)">
                        mdi-delete
                    </v-icon>
                    <v-icon small v-if="item.shopify_id == null" v-on:click="openInvitation(item)">
                        mdi-send
                    </v-icon>
                </td>
            </template>

        </v-data-table>
        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
            {{ snackbartext }}
            <v-btn
                dark
                text
                @click="snackbar = false"
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>

        </v-snackbar>
        <v-dialog
            v-model="loadingDialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <v-img
                        src = 'https://cdn.shopify.com/s/files/1/0306/7215/8765/t/12/assets/loadding.svg?v=8868230531791137616'
                        height="350px"
                        contain
                    ></v-img>
                    <v-col cols="12" class="d-flex justify-center">
                        <span class="h4">Please stand by</span>
                    </v-col>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="invitation" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline font-weight-bold text-uppercase grey--text">Alert</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-col cols="12">
                            <span class="subtitle-1">Are you sure you want to send an invitation to this user?</span>
                        </v-col>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="sendInvitation">Accept</v-btn>
                    <v-btn color="blue darken-1" text @click="closeInvitation">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import DateComponent from "../../components/DateComponent";
    import CompanyInformationPopUp from "../../components/CompanyInformationPopUp";
    import ContactInformationPopUp from "../../components/ContactInformationPopUp";
    export default {
        name: "Dashboard",
        components: {ContactInformationPopUp, CompanyInformationPopUp, DateComponent},
        data() {
            return {
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
                invitation: false,
                search: '',
                dialog: false,
                editedCompany: [],
                editedContact: [],
                invitedItemId: -1,
                notification: false,
                isCompany: false,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: 'Email', value: 'billing_email'},
                    {text: 'Status', value: 'status'},
                    {text: 'Points', value: 'points_generated'},
                    {text: 'Current Plan', value:'plans' },
                    {text: 'Registration Date', value: 'created_at'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                homeowner: [],
                prousers: [],
                users: [],
                loading: true,
                loadingDialog: false,
                editedCompanyIndex: -1,
                editedUserIndex: -1,
                deletedItemId: -1,
            }
        },

        computed: {
            formTitle () {
                return this.isCompany === true ? 'Edit Company Information' : 'Edit Contact Information'
            },
        },

        mounted() {
            axios.get('/api/clients').then(res => {
                this.homeowner = res.data;
            });


            this.getCompany();
        },

        methods:{
            companyRequest: function(data){
                return {
                    "name": data.name,
                    "billing_address": {
                        "address1":data.billing_address.address1,
                        "address2":data.billing_address.address2,
                        "country_id": data.billing_address.country_id,
                        "state_id": data.billing_address.state_id,
                        "city_id": data.billing_address.city_id,
                        "post_code": data.billing_address.post_code
                    },
                    "dba": data.dba,
                    "category_id":"1",
                    "company_type_id":"1",
                    "shop_preference_id":"1",
                    "status": data.status,
                    "ein": data.ein,
                    "phone": data.phone,
                    "fax": data.fax,
                    "billing_email": data.billing_email,
                    "website": data.website,
                    "plan_id": data.plans[0].id
                }
            },

            sendInvitation(){
                axios.post('/api/proinvitations/' + this.invitedItemId)
                    .then(response => {
                        let res = response;
                        this.activateSnackbar(`You have successfully invited the user`, 'success');
                    })
                    .catch(error=> {
                        this.activateSnackbar(`Error`, 'error')
                    })

                this.closeInvitation();
            },

            getCompany: function(){
                axios.get('/api/companies').then(res => {
                    this.prousers = res.data.companies;
                    console.log(this.prousers);
                    this.users = [];
                    for (var i = 0; i < this.prousers.length; i++) {
                        this.users.push(this.prousers[i]);
                        this.loading = false;
                    }
                });
            },

            goToCompany: function (item){
                this.$router.push(`/companies/${item.id}`)
            },

            editItem: function (item, isCompany) {
                if (isCompany===true){
                    this.editedIndex = this.prousers.indexOf(item);
                    this.editedCompany = Object.assign({}, item);
                    this.isCompany = true;
                }else{
                    this.editedContact = Object.assign({}, item);
                    this.isCompany = false;
                }
                this.dialog = true
            },


            openNotification: function (item, isCompany, parentitem) {
                if (isCompany===true){
                    this.notification = true;
                    this.isCompany = true;
                    this.editedCompanyIndex = this.users.indexOf(item);
                    this.deletedItemId = item.id;
                }else{
                    this.notification = true;
                    this.isCompany = false;
                    this.editedCompanyIndex = this.users.indexOf(parentitem);
                    let userIndex= this.users[this.editedCompanyIndex].contacts.indexOf(item);
                    this.editedUserIndex = userIndex;
                    this.deletedItemId = item.id;
                    console.log(this.deletedItemId);
                }
            },

            openInvitation: function (item)
            {
                this.invitation = true;
                this.editedIndex = this.prousers.indexOf(item);
                this.invitedItemId = item.id;
            },

            deleteitem: function(){
                if (this.isCompany===true){
                    this.users.splice(this.editedCompanyIndex, 1);
                    axios.delete('/api/companies/' + this.deletedItemId)
                        .then(response => {
                            let res = response;
                            console.log(response);
                            this.activateSnackbar(`You have successfully deleted the company information`, 'success')
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`Error`, 'error')
                        });
                }else {
                    this.users[this.editedCompanyIndex].contacts.splice(this.editedUserIndex, 1);
                    axios.delete('/api/contacts/' + this.deletedItemId)
                        .then(response => {
                            let res = response;
                            console.log(response);
                            this.activateSnackbar(`You have successfully deleted the contact information`, 'success')
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`Error`, 'error')
                        });
                }
                this.close();
            },

            close () {
                this.dialog = false;
                this.notification=false;
                this.editedCompanyIndex = -1;
                this.editedUserIndex = -1;
                this.deletedItemId = -1;
            },

            closeInvitation () {
                this.invitation = false;
                this.editedIndex = -1;
                this.invitedItemId = -1;
            },

            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },

            save () {
                if (this.isCompany) {
                    Object.assign(this.prousers[this.editedIndex], this.editedCompany)
                    console.log(this.editedCompany);
                    console.log(this.companyRequest(this.editedCompany));
                    axios.put('/api/companies/' + this.prousers[this.editedIndex].id, this.companyRequest(this.editedCompany))
                        .then(response => {
                            let res = response;
                            this.activateSnackbar(`You have successfully edited ${res.data.company.name} information`, 'success')
                            this.getCompany();
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`Error`, 'error')
                        });
                } else {
                   Object.assign(this.prousers[this.editedIndex].contact)
                }
                this.close()
            },

            getColor: function (status) {
                if (status === 'Inactive') return 'red';
                else if (status === 'Active') return 'green'
                else return ''
            },

            searchNewUsers(){
                this.loadingDialog = true;
                axios.get('/api/newaccounts').then(res => {
                    this.getCompany();
                    this.loadingDialog = false;
                });
            }
        },
    };
</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }

    .container{
        max-width: 100vw;
    }

    .test{
        background-color: rgba(0, 0, 0, 0);
    }
</style>
