<template>
    <v-app>
        <v-content class="back">
            <v-container class="my-5">
                <v-card flat class="plans">
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-col cols="12">
                                    <div class="caption grey--text"> Plan name</div>
                                    <v-card-title>
                                        <span class="headline font-weight-bold text-uppercase grey--text">{{ plan.name }}</span>
                                    </v-card-title>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <div class="caption grey--text"> Monthly Price</div>
                                    <div>${{ plan.monthly_price}}</div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="caption grey--text"> Six Month Price</div>
                                    <div>${{ plan.six_months_price}}</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <div class="caption grey--text"> Use Points Pefore(months)</div>
                                    <div>{{ plan.expired_time_points}}</div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="caption grey--text"> Decisions Makers Number</div>
                                    <div>{{ plan.total_decision_maker}}</div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="caption grey--text"> % Over Sale/Purchase</div>
                                    <div>{{ plan.point_value}}</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <template>
                                <v-container fluid>
                                    <v-data-iterator
                                            :items="plan.benefit_plan"
                                            hide-default-footer>
                                        <template v-slot:header>
                                            <v-card-title>
                                                <span class="headline font-weight-bold text-uppercase grey--text">Benefits - Role</span>
                                            </v-card-title>
                                        </template>

                                        <template v-slot:default="props">
                                            <v-row>
                                                <v-col
                                                        v-for="item in props.items"
                                                        :key="item.name"
                                                        order-by="item.name"
                                                        cols="12"
                                                        sm="6"
                                                        md="4"
                                                        lg="3"
                                                >
                                                    <v-card height="350">
                                                        <v-card-title height="100">
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-list-item-title class="headline mb-1 grey--text">{{ item.benefit.name }}</v-list-item-title>
                                                                    <v-list-item-subtitle>Account Type: {{ item.role.name }}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card-title>
                                                        <v-divider></v-divider>
                                                        <v-card-text height="250">
                                                            <v-list dense>
                                                                <v-list-item>
                                                                    <v-list-item-content>Quantity:</v-list-item-content>
                                                                    <v-list-item-content class="align-end">{{ item.value }}</v-list-item-content>
                                                                </v-list-item>

                                                                <v-list-item>
                                                                    <v-list-item-content>Renewable:</v-list-item-content>
                                                                    <v-list-item-content v-if="item.renewable" class="align-end">Yes</v-list-item-content>
                                                                    <v-list-item-content v-else="item.renewable" class="align-end">No</v-list-item-content>
                                                                </v-list-item>

                                                                <v-list-item>
                                                                    <v-list-item-content>Renewal value:</v-list-item-content>
                                                                    <v-list-item-content class="align-end">{{ item.renewal_value }}</v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-data-iterator>
                                </v-container>
                            </template>

                            <!--<v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Calories</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in plan.benefit_plan" :key="item.id">
                                        <td>{{ item.benefit.name }}</td>
                                        <td>{{ item.value }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>-->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn text router :to="`/plans`">Cancel</v-btn>
                        <v-btn color="#eabd75" router :to="`/manageplans/edit/${plan.id}`">
                            <v-icon> mdi-comment-edit </v-icon>
                            Edit
                        </v-btn>
                    </v-row>
                    <v-divider></v-divider>
                </v-card>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>

    export default {
        name: "InfoPlan",
        data() {
            return {
                planId: -1,
                plan: [],
            }
        },
        mounted() {
            this.planId = this.$route.params.id;
            axios.get('/api/plans/'+this.planId).then(res => {
                this.plan = res.data.plan;
            });
        },

    }
</script>

<style scoped>
    .plans{
        border-top: 4px solid #eabd75;
    }
    .header-card-b{
        border: 4px solid #eabd75;
    }
    .back{
        z-index: 0;
    }
</style>
