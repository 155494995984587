<template>
    <v-app>
        <v-container fluid class="mx-0 px-0 lighten-5">
            <v-row class='mb-6' >
                <v-col cols="6">
                    <v-card class="pa-2 border-card" :elevation="6" height="314">
                        <div class='h5 pa-2 mb-0'>Invoice Information</div>
                        <v-row no-gutters>
                            <v-col cols=7 class='py-0'>
                                <v-card outlined class="mx-1" style='height:100%'>
                                    <div class="h5 px-3 pt-3">General Information</div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Sales Number:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{salesDetails.number}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1' v-if="salesDetails.user.contact!=null">
                                        <div class="h6">Company Name:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{salesDetails.user.contact.company.name}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Client Name:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{salesDetails.user.contact? salesDetails.user.contact.first_name:salesDetails.user.client.first_name}} {{salesDetails.user.contact? salesDetails.user.contact.last_name: salesDetails.user.client.last_name}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Client Type:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{salesDetails.user.contact? "Professional Account" : "Personal Account"}}</div>

                                    </div>
                                    <div class="h5 px-3 pt-3">Invoice Status</div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Status:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{salesDetails.status.charAt(0).toUpperCase() + salesDetails.status.slice(1).toLowerCase() }}</div>
                                        <div class="h6">Stage:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{salesDetails.stage}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Source:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{salesDetails.shop.name}}</div>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols=5 class='py-0'>
                                <v-card outlined class='mb-1'>
                                    <div class="h5 px-3 pt-3 pb-0">Discount</div>
                                    <div class="px-3 py-1 d-flex justify-space-between">
                                        <div class="headline mb-1 " v-if="!discounted">$ {{salesDetails.discount_total}}</div>
                                        <div class="headline mb-1 " v-else>$ {{discount_ad}}</div>
                                        <v-icon medium>mdi-cash-usd</v-icon>
                                    </div>
                                    <div class="overline pa-3">Discount Applied</div>
                                </v-card>
                                <v-card outlined>
                                    <div class="h5 px-3 pt-3 pb-0">Total</div>
                                    <div class="px-3 py-1 d-flex justify-space-between">
                                        <div class="headline mb-1 " v-if="!discounted">$ {{salesDetails.total}}</div>
                                        <div class="headline mb-1 " v-else>$ {{total_ad}}</div>
                                        <v-icon medium>mdi-cash-usd</v-icon>
                                    </div>
                                    <div class="overline pa-3">Amount after Discount</div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card :elevation="6" class="pa-2 border-card" >
                        <div class='h5 pa-2'>Available Points</div>
                        <v-list-item three-line>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline mb-1" v-if="!discounted">{{salesDetails.points_available}}</v-list-item-title>
                                <v-list-item-title class="headline mb-1" v-else>{{point_ad}}</v-list-item-title>
                                <v-list-item-subtitle>Total Points Available</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar tile size="50">
                                <v-icon>mdi-tag</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                    <v-card :elevation="6" class=" mt-2 pa-2 border-card" >
                        <div class='h5 pa-2'>Rewards Points</div>
                        <v-list-item three-line>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline mb-1">{{salesDetails.total_earned_points}}</v-list-item-title>
                                <v-list-item-subtitle>Total Points to be Rewarded</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar tile size="50">
                                <v-icon>mdi-tag-plus</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>

                </v-col>
                <v-col cols="3">
                    <v-card :elevation="6" class="pa-2 border-card" height="314">
                        <div class="d-flex flex-column" style="height:100%">
                            <div class='h5 pa-2'>Points to Redeem</div>
                            <div class="d-flex flex-column">
                                <span class='h5 px-2 py-3'  align="center">Once you clic on Redem all points button, we will show your new balance and you will need to confirm.</span>

                            </div>
                            <div class='d-flex' style="height:100%;flex-direction: column;justify-content: flex-end;" >
                                <div class='pt-2'>
                                    <v-btn color="#eabd75" block :disabled="(redeem || salesDetails.stage ==='Dispatched' || salesDetails.points_available==0) ? true :  RedeemIsNotEmpty?  formIsValid ? false : true : false" @click='PopPup'>
                                        <span v-if="RedeemIsNotEmpty"> Redeem Points </span>
                                        <span v-else> Redeem All Points </span>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="notification" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline font-weight-bold text-uppercase grey--text">Alert</span>
                    </v-card-title>

                    <v-card-text >
                        <v-container>
                            <v-col cols="12">
                                <span class="subtitle-1">Are you sure you want to redeem {{redeemValue}} points?</span>
                            </v-col>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="manualDiscount">Accept</v-btn>
                        <v-btn color="blue darken-1" text @click="notification=false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="loadingDialog"
                persistent
                max-width="500px"
            >
                <v-card>
                    <v-card-text>
                        <v-img
                            src = 'https://cdn.shopify.com/s/files/1/0306/7215/8765/t/12/assets/loadding.svg?v=8868230531791137616'
                            height="350px"
                            contain
                        ></v-img>
                        <v-col cols="12" class="d-flex justify-center">
                            <span class="h4">Please stand by</span>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-dialog>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
        >
            <v-card class="">
                <v-card-title>
                    <span class="headline font-weight-bold text-uppercase grey--text">Alert</span>
                </v-card-title>
                <v-card-title class="headline mx-auto">
                    <span class='h5 pa-2' align="center" >After clicking Accept, your new balance will be:</span>
                </v-card-title>

                    <!--<v-card-text></v-card-text>-->
                <div>
                    <v-row class="pa-4"
                        no-gutters
                    >
                            <v-col cols="6" class="pa-2">
                                <v-card class=" pa-2 border-card"  max-width="400">
                                    <div class='h5 '>Discount</div>
                                    <v-list-item three-line>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="headline mb-1"><span>$</span>{{discount_ad}}</v-list-item-title>
                                            <v-list-item-subtitle>Discount total</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-avatar tile size="50">
                                            <v-icon>mdi-cash-usd</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                </v-card>

                            </v-col>

                            <v-col cols="6" class="pa-2">
                                <v-card class=" pa-2 border-card"  max-width="400">
                                    <div class='h5 '>Points Remaining</div>
                                    <v-list-item three-line>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="headline mb-1">{{points_remaining}}</v-list-item-title>
                                            <v-list-item-subtitle>Total Points Remaining</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-avatar tile size="50">
                                            <v-icon>mdi-tag-plus</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                </v-card>

                            </v-col>

                    </v-row>

                    <v-row class="pa-4"
                        no-gutters align="center"
                    >
                        <v-col cols="6" class="pa-2" >
                            <v-card class=" pa-2 border-card"  max-width="400">
                                <div class='h5 '>Total</div>
                                <v-list-item three-line>
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="headline mb-1"><span>$</span>{{total_ad}}</v-list-item-title>
                                        <v-list-item-subtitle>Total with discount</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar tile size="50">
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-card>

                        </v-col>

                        <v-col cols="6" class="pa-2">
                            <v-card  class=" pa-2 border-card" max-width="400">
                                <div class='h5 '>Rewards Points</div>
                                <v-list-item three-line>
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="headline mb-1">{{desc}}</v-list-item-title>
                                        <v-list-item-subtitle>Points you would earn after purchasing with your available points to redeem:</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar tile size="50">
                                        <v-icon>mdi-tag-plus</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-card>

                        </v-col>



                    </v-row>
            </div>
                <v-card-title class="headline ">
                    <span class='h5 mx-auto' align="center" style="color:red"> This action can't be undone</span>
                </v-card-title>
                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn
                        color="#eabd75"
                        text
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                    color="#eabd75"
                    text
                    @click.stop="redeemAllPoints"
                    class="font-weight-black"
                    >
                    Accept
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        </v-container>
        <v-data-table :headers="headers"
                      :items="salesLineItemDetails"
                      item-key="id"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait">
        </v-data-table>
    </v-app>



</template>

<script>
export default {
    name: "Redeeming Sales Details",

    data() {
        return {
            saleId: -1,
            headers: [
                {
                    text: 'Code',
                    align: 'start',
                    value: 'product.code',
                },
                {text: 'Product Description', value: 'product.name'},
                {text: 'Quantity', value: 'quantity'},
                {text: 'Unit Price', value: 'unit_price'},
                {text: 'Item total', value: 'line_item_total'},
                {text: 'Total Points', value: 'line_item_points'},
            ],
            salesDetails: [],
            salesLineItemDetails: [],
            loading: true,
            loadingDialog: false,
            rules: [],
            redeem: true,
            redeemValue: '',
            point_ad: '',
            desc: '',
            points_remaining: '',
            discount_ad:'',
            total_ad: '',
            availablePoints:'',
            validForm: false,
            notification: false,
            discount: false,
            discounted:false,
            dialog: false,
        }
    },

    computed: {
        formIsValid () {
            if (this.validForm){
              return true
            }else{
              return false
            }
        },
        RedeemIsNotEmpty(){
            if (this.redeemValue){
                return true
            }else{
                return false
            }
        }
    },

    mounted() {
        this.getSalesDetails()
    },

    methods: {
        PopPup(){
            this.dialog=true;
            this.redeemValue = this.availablePoints;
            this.calculateDiscount();
        },
        getSalesDetails(){
            this.saleId = this.$route.params.id;
            axios.get('/api/sales/'+this.saleId).then(res => {
                this.salesDetails = res.data.invoices;
                console.log(this.salesDetails)
                this.salesLineItemDetails= res.data.invoices.sale_details;
                this.loading = false;
                this.availablePoints = this.salesDetails.points_available;
                this.rules=[v => v <= this.salesDetails.points_available || 'Max should not be above Available Points',]
                if ( this.salesDetails.hasOwnProperty('redeem_transaction')){
                    this.redeem = true;
                    this.redeemValue = this.salesDetails.redeem_transaction.points;
                } else {
                    this.redeem = false;
                }
            });
        },
        redeemRequest: function(){
            if (this.discounted){
                return {
                    "sale_id": this.saleId,
                    "points_to_redeem": this.redeemValue,
                    "new_discount": this.discount_ad,
                    "total_after_discount": this.total_ad
                }
            }else{
                return {
                    "sale_id": this.saleId,
                    "points_to_redeem": this.redeemValue,
                }
            }
        },
        clearMessage () {
            this.message = ''
            this.$refs['redeem'].reset()
        },
        redeemAllPoints(){
            if (this.RedeemIsNotEmpty){
                this.notification = true
            }else{
                //this.redeemValue = this.availablePoints;
                this.dialog=false;
                this.manualDiscount()
            }
        },
        calculateDiscount(){
            this.loadingDialog = true;
            axios.post('/api/manualsalesdiscount',{
                sale_id: this.saleId,
                points_to_redeem: this.redeemValue
            }).then(res => {
                this.discount = true;
                this.discount_ad= res.data.new_discount;
                this.total_ad= res.data.total_after_discount;
                this.point_ad= res.data.available_points_ad;
                this.desc= res.data.rewards_points_ad;
                this.points_remaining=res.data.points_remaining;
                this.loadingDialog = false;
                this.discounted=true;
            });
        },
        showDiscount: function () {
            var toggle_button= document.getElementsByClassName('toggle-btn')[0];
            var approve_text = document.getElementsByClassName('approve')[0];
            var deny_text = document.getElementsByClassName('deny')[0];
            toggle_button.style.marginLeft = "52%";
            approve_text.classList.remove("active");
            deny_text.classList.add("active");
            this.discounted=true;
        },
        showOriginal: function () {
            var toggle_button= document.getElementsByClassName('toggle-btn')[0];
            var approve_text = document.getElementsByClassName('approve')[0];
            var deny_text = document.getElementsByClassName('deny')[0];
            toggle_button.style.marginLeft = "4px";
            approve_text.classList.add("active");
            deny_text.classList.remove("active");
            this.discounted=false;
        },
        manualDiscount(){
            this.notification = false;
            this.loadingDialog = true;
            axios.post('/api/manualredeem',this.redeemRequest()).then(res => {
                this.discount = false;
                this.loadingDialog = false;
                this.discounted=false;
                this.getSalesDetails();
            });
        }
    },

}
</script>

<style scoped>
    .pricing-tab-wrapper{
        display:flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        margin: 0 auto 8px auto;
    }
    .pricing-tab{
        position: relative;
    }
    .pricing-tab span{
        width: 50%;
        vertical-align: middle;
        position: relative;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        text-align:center;
        transition: 0.5s all ease;
    }
    .pricing-tab .toggle-slide{
        border: solid 1px;
        display: flex;
        background: white;
        border-radius:20px;
        margin: 0 auto;
        height:35px;
        cursor: pointer;
        user-select: none;
        outline: 0;
        width: 156px;
        box-shadow: 2px 4px 9px 0px rgba(119, 119, 119, 0.4);
    }
    .label-text{
        font-size: 14px;
        line-height: 1;
        color: #eabd75;
    }
    .toggle-btn{
        margin-left: 52%;
        left: 0px;
        width: 70px;
        background: #eabd75;
        border-color: #eabd75;
        border-radius: 20px;
        height: 25px;
        margin-top: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s ease-in-out;
    }
    .pricing-tab span.approve,
    .pricing-tab span.deny{
        position: absolute;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .pricing-tab span.approve{
        left: 1px;
    }
    .approve.active, .deny.active{
        color: white;
    }
    .border-card{
        border-bottom: 4px solid #eabd75;
    }
</style>
