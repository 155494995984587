<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark v-bind="attrs" v-on="on" class='GrofUSA mb-3' color="#eabd75" fab hover small :to="`/manageproducts`">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Create New Product</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark v-bind="attrs" v-on="on" class='GrofUSA mb-3' color="#eabd75" fab hover small :to="`/products/import`" style="margin: 0 15px;">
                            <v-icon>mdi-file-download</v-icon>
                        </v-btn>
                    </template>
                    <span>Import Products</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark v-bind="attrs" v-on="on" class='GrofUSA mb-3' color="#eabd75" fab hover small :to="`/products/price`">
                            <v-icon>mdi-playlist-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>Price Update</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                    <v-progress-circular
                    v-if="processSyncActive"
                    indeterminate
                    color="#eabd75"
                    >
                    <span>sync</span>
                </v-progress-circular>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                        v-model="search"
                        label="Search Ex: 1900 (Press Enter)"
                        single-line
                        hide-details
                        clearable
                        @keydown.enter="searchQuery"
                        @click:clear="getList"
                    ></v-text-field>
                </v-col> 
            </v-row>
        </v-container>
        
        <v-data-table :headers="headers"
                    :items="products"
                    item-key="id"
                    :search="search"
                    class="elevation-2"
                    :loading="loading"
                    :items-per-page="25"
                    @click:row="goToEditProduct"
                    hide-default-footer
                    loading-text="Loading... Please wait">
            <template v-slot:item.actions="{item, header, value}">
                <td @click.stop class="non-clickable">
                    <v-icon
                    small
                    class="mr-2"
                    @click="editProduct(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                    small
                    @click="openNotification(item)"
                    >
                        mdi-delete
                    </v-icon>
                </td> 
            </template>

            <template v-slot:top>
                <v-dialog v-model="notification" max-width="500px">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-col cols="12">
                                    <span class="subtitle-1">Are you sure you want to delete this product?</span>
                                </v-col>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="deleteItem">Accept</v-btn>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template v-slot:item.brand.name="{item, header, value}">
                <span v-if="value == 'not assign'" class='brandTag'>
                    {{item.brand_name}}
                </span>
                <span v-else class='brandTag'>
                    {{item.brand.name}}
                </span>
            </template>

        </v-data-table>
        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
            {{ snackbartext }}
            <v-btn  dark text @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
        <div class="text-center" style="padding: 15px 0;" v-if="lastPage !== 0">
            <v-pagination
            v-model="currentPage"
            color="#eabd75"
            :length="lastPage"
            :total-visible="8"
            ></v-pagination>
        </div>
    </v-app>
</template>

<script>

    export default {
        name: "Dashboard",

        data() {
            return {
                search: '',
                headers: [
                    {
                        text: 'Product',
                        align: 'start',
                        value: 'name',
                    },
                    {text: 'Variant', value: 'variant_title'},
                    {text: 'Code', value: 'code'},
                    {text: 'Brand', value: 'brand.name'},
                    {text: 'Price Showroom (US$)', value: 'price_showroom_usd'},
                    {text: 'Price Ecommerce (US$)', value: 'price_ecommerce_usd'},
                    {text: 'Price Showroom Pro (US$)', value: 'price_showroom_trade_usd'},
                    //{text: 'Price Ecommerce Pro', value: 'price_ecommerce_trade_usd'},
                    {text: 'Actions', value: 'actions',  sortable: false}
                    ],
                products: [],
                loading: true,
                processSyncActive: false,
                currentPage: 0,
                lastPage: 0,
                pageNumber: 0,
                notification: false,
                productId: -1,
                index: -1,
                deletedItemId: -1,
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
            }
        },
        watch: {

            currentPage ( newVal ) {
                if (this.search == '' || this.search == null) {
                    this.getList(newVal);
                    
                } else {
                    this.searchQuery(newVal);
                } 
            },

        },

        computed: {
        },

        mounted() {
            axios.get('/api/products_gsrp').then(res => {
                //console.log(res.data);
                this.loading = false;
                this.products = res.data.products.data;
                this.currentPage = res.data.products.current_page;
                this.lastPage = res.data.products.last_page;
            });
            axios.get('/api/checksyncprocessproducts').then(res => {
                //console.log(res.data);
                if (res.data.active) {
                    this.processSyncActive = true;
                }
            });
        },

        methods: {
            deleteItem: function(){
                this.loading = true;
                this.products.splice(this.index, 1);
                axios.delete('/api/products/' + this.deletedItemId)
                    .then(response => {
                        this.loading = false;
                        let res = response;
                        this.activateSnackbar(`You have successfully deleted the product`, 'success')
                    })
                    .catch(error=> {
                        console.log(error);
                        this.activateSnackbar(`Error`, 'error')
                        this.loading = false;
                    });
                this.close();
            },

            editProduct(e) {
                this.e1 = e;
                console.log("editar",this.e1.id);
                console.log(this.$route);
                this.$router.push('/manageproducts/edit/'+this.e1.id);
            },

            getList ( pageNumber ) {
                axios.get('/api/products_gsrp?page=' + pageNumber).then(res => {
                    //console.log(res.data);
                    this.loading = false;
                    this.products = res.data.products.data;
                    this.currentPage = res.data.products.current_page;
                    this.lastPage = res.data.products.last_page;
                });
            },

            searchQuery ( pageNumber = 0 ) {
                if (this.search != '') {
                    //console.log(this.search);
                    this.loading = true;
                    axios.get('api/searchproducts?page=' + pageNumber, { params: { query: this.search } })
                    .then(res => {
                        this.loading = false;
                        this.products = res.data.products.data;
                        this.currentPage = res.data.products.current_page;
                        this.lastPage = res.data.products.last_page;
                        //console.log(res)    
                    })
                    .catch(error => { console.log(error) });
                }
            },

            openNotification: function (item) {
                this.notification = true;
                this.index = this.products.indexOf(item);
                this.deletedItemId = item.id;
            },

            close () {
                this.productId = -1;
                this.notification=false;
                this.index = -1;
                this.deletedItemId = -1;
            },

            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },

            goToEditProduct(item){
                this.$router.push(`/manageproducts/edit/${item.id}`)
            },

        },
    };
</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }

    .brandTag{
        font-weight: bold;
        text-transform: uppercase;
    }

</style>
