<template>
    <v-app id="inspire">
        <v-container class="fill-height" fluid >
            <v-row align="center" justify="center" >
                <v-col cols="12" sm="8" md="5" >
                    <v-card class="elevation-12">
                        <v-row justify="center" align-content="center">
                            <v-col cols="12" xl='12' md='12' xs='12'>
                                <v-img src="/images/grof.png" height="100px" position="center" contain style="margin-top: 20px"></v-img>
                            </v-col>
                            <v-col cols="12" xl='12' md='12' xs='12' class='justify-center'>
                                <div class="d-flex justify-center">
                                    <h4 style="margin-top: 15px; margin-bottom: 15px">Log In to Your Account</h4>
                                </div>
                            </v-col>
                        </v-row>
                        <v-card-text style="padding-left: 20px; padding-right: 20px">
                            <v-form>
                                <v-text-field label="Email" name="Login" v-model="user.username" prepend-icon="mdi-account" type="text" ></v-text-field>
                                <v-text-field id="password" label="Password" v-model="user.password" name="password" prepend-icon="mdi-lock" type="password"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-row justify="center" align-content="center">
                                <v-btn color="#eabd75" v-on:click="login" width="100" dark style="margin-bottom: 10px">Login</v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
                v-model="dialog"
                width="300"
            >
                <v-card>
                    <v-card-text>
                        <v-row
                            justify="center"
                            class="pa-6"
                        >
                            <v-progress-circular
                                :size="120"
                                color="#eabd75"
                                indeterminate
                            ></v-progress-circular>
                        </v-row>
                        <v-row
                            justify="center"
                        >
                            <span class="font-weight-black">Checking your Credentials</span>
                        </v-row>
                        <v-row
                            justify="center"
                        >
                            <span class="font-weight-black">Please Wait</span>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="error" max-width="500">
                <v-card>
                     <v-card-title>
                        <span class="title font-weight-bold text-capitalize grey--text">Error</span>
                    </v-card-title>

                    <v-card-text>

                        <v-col cols="12">
                            <span class="body-1">{{errorMessage}}</span>
                        </v-col>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-app>
</template>

<script>
    export default {
        data:()=>({
            dialog:false,
            error: false,
            errorMessage:'',
            user:{
                username:'',
                password:'',
            },
        }),
        methods:{
            close(){
                this.error = false;
            },

            login(){
                this.dialog= true
                this.$store
                    .dispatch("retrieveToken", {
                    email: this.user.username,
                    password: this.user.password,
                    })
                    .then(response => {
                        if (response.status === 200){
                            this.loading = false;
                            this.$router.push('/');
                        }
                    })
                    .catch(error => {
                        this.dialog = false;
                        this.error = true;
                        if (error.response.status==500){
                            this.errorMessage = error.response.data.error
                        }
                    });
        },

        }
    }
</script>
