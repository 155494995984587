var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{staticClass:"mr-0 ml-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"#eabd75"},on:{"click":function($event){$event.stopPropagation();return _vm.searchNewInvoices($event)}}},[_c('v-icon',[_vm._v("mdi-cached")])],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"my-0 pt-0",attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sales,"item-key":"id","search":_vm.search,"sort-by":['date'],"sort-desc":[true],"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.goToDetails},scopedSlots:_vm._u([{key:"item.shop.name",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_vm._v("\n            "+_vm._s(_vm.shopName(value))+"\n        ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_vm._v("\n            "+_vm._s(_vm.capitalizeLetter(value))+"\n        ")]}},{key:"item.stage",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_vm._v("\n            "+_vm._s(_vm.capitalizeLetter(value))+"\n        ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_c('date-component',{attrs:{"registration":value}})]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}},[_c('v-card',[_c('v-card-text',[_c('v-img',{attrs:{"src":"https://cdn.shopify.com/s/files/1/0306/7215/8765/t/12/assets/loadding.svg?v=8868230531791137616","height":"350px","contain":""}}),_vm._v(" "),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"h4"},[_vm._v("Please stand by")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }