<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                        v-model="search"
                        label="Search Ex: Peter James"
                        single-line
                        hide-details
                        clearable
                        @keydown.enter="searchQuery"
                        @click:clear="getList"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-data-table
            :headers="headers"
            :items="users"
            item-key="id"
            :search="search"
            :sort-by="['created_at']"
            :sort-desc="[true]"
            class="elevation-1"
            :loading="loading"
            :items-per-page="25"
            hide-default-footer
            loading-text="Loading... Please wait"
        >
            <template v-slot:top>
                <v-dialog v-model="notification" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span
                                class="headline font-weight-bold text-uppercase grey--text"
                                >Alert</span
                            >
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-col cols="12">
                                    <span class="subtitle-1"
                                        >Are you sure you want to delete this
                                        user?</span
                                    >
                                </v-col>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteitem"
                                >Accept</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="close"
                                >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template v-slot:item.created_at="{ item, header, value }">
                <date-component :registration="value"></date-component>
            </template>

            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon small v-on:click="openNotification(item)">
                        mdi-delete
                    </v-icon>
                    <v-icon
                        small
                        v-if="item.shopify_id == null"
                        v-on:click="openInvitation(item)"
                    >
                        mdi-send
                    </v-icon>
                </td>
            </template>
        </v-data-table>

        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
            {{ snackbartext }}
            <v-btn dark text @click="snackbar = false">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </v-snackbar>

        <v-dialog v-model="invitation" max-width="500px">
            <v-card>
                <v-card-title>
                    <span
                        class="headline font-weight-bold text-uppercase grey--text"
                        >Alert</span
                    >
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-col cols="12">
                            <span class="subtitle-1"
                                >Are you sure You want to send an invitation to
                                this user?</span
                            >
                        </v-col>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="sendInvitation"
                        >Accept</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="closeInvitation"
                        >Cancel</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="text-center" style="padding: 15px 0;" v-if="lastPage !== 0">
            <v-pagination
                v-model="currentPage"
                color="#eabd75"
                :length="lastPage"
                :total-visible="8"
            ></v-pagination>
        </div>
    </v-app>
</template>

<script>
import DateComponent from "../../components/DateComponent";
export default {
    name: "PersonalAccounts",
    components: { DateComponent },
    data() {
        return {
            search: "",
            loading: true,
            snackbar: false,
            snackbartext: "",
            snackbarcolor: "",
            editedUserIndex: "",
            notification: false,
            invitation: false,
            invitedItemId: "",
            deletedItemId: "",
            response: null,
            currentPage: 0,
            lastPage: 0,
            pageNumber: 0,
            users: [],
            headers: [
                {
                    text: "First Name",
                    align: "start",
                    sortable: false,
                    value: "first_name"
                },
                { text: "Last Name", value: "last_name" },
                { text: "Email", value: "email" },
                { text: "Registration Date", value: "created_at" },
                { text: "Available Points", value: "points_generated" },
                { text: "Redeemed Points", value: "points_redeemed" },
                { text: "Actions", value: "actions", sortable: false }
            ]
        };
    },

    watch: {
        currentPage(newVal) {
            if (this.search == "" || this.search == null) {
                this.getList(newVal);
            } else {
                this.searchQuery(newVal);
            }
        }
    },

    mounted() {
        axios.get("/api/clients").then(res => {
            this.response = res.data.clients.data;
            this.users = [];
            for (let user of this.response) {
                this.users.push(user);
            }
            this.currentPage = res.data.clients.current_page;
            this.lastPage = res.data.clients.last_page;
            this.loading = false;
        });
    },

    methods: {
        openNotification: function(item) {
            this.notification = true;
            this.editedUserIndex = this.users.indexOf(item);
            this.deletedItemId = item.id;
        },

        openInvitation: function(item) {
            this.invitation = true;
            this.editedUserIndex = this.users.indexOf(item);
            this.invitedItemId = item.id;
        },

        deleteitem: function() {
            this.users.splice(this.editedUserIndex, 1);
            axios
                .delete("/api/clients/" + this.deletedItemId)
                .then(response => {
                    let res = response;
                    this.activateSnackbar(
                        `You have successfully deleted the user information`,
                        "success"
                    );
                })
                .catch(error => {
                    this.activateSnackbar(`Error`, "error");
                });

            this.close();
        },

        sendInvitation() {
            axios
                .post("/api/invitations/" + this.invitedItemId)
                .then(response => {
                    let res = response;
                    this.activateSnackbar(
                        `You have successfully invited the user`,
                        "success"
                    );
                })
                .catch(error => {
                    this.activateSnackbar(`Error`, "error");
                });

            this.closeInvitation();
        },

        activateSnackbar(text) {
            this.snackbar = true;
            this.snackbartext = text;
        },

        close() {
            this.notification = false;
            this.editedUserIndex = -1;
            this.deletedItemId = -1;
        },

        getList(pageNumber) {
            axios.get("/api/clients?page=" + pageNumber).then(res => {
                this.loading = false;
                this.users = res.data.clients.data;
                this.currentPage = res.data.clients.current_page;
                this.lastPage = res.data.clients.last_page;
            });
        },

        searchQuery(pageNumber = 0) {
            if (this.search != "") {
                this.loading = true;
                axios
                    .get("api/searchclients?page=" + pageNumber, {
                        params: { query: this.search }
                    })
                    .then(res => {
                        this.users = res.data.clients.data;
                        this.currentPage = res.data.clients.current_page;
                        this.lastPage = res.data.clients.last_page;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },

        closeInvitation() {
            this.invitation = false;
            this.editedUserIndex = -1;
            this.invitedItemId = -1;
        }
    }
};
</script>

<style scoped>
::v-deep tbody td {
    cursor: pointer;
}

::v-deep tbody td td.non-clickable {
    cursor: auto;
}

.container {
    max-width: 100vw;
}
</style>
