<template>
    <v-app>

        <v-container v-if="newProduct">
            <v-row>
                <v-col class="d-flex" cols="12" sm="6" md="6">
                    <v-select
                        v-model="shop"
                        :items="shopList"
                        label="Shopify Source"
                    ></v-select>
                </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
        </v-container>

        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
            {{ snackbartext }}
            <v-btn  dark text @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>

        <v-container v-if="loading">
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
            >
                <v-col
                    class="subtitle-1 text-center"
                    cols="12"
                >
                    Loading... Please Wait and don't reload the page!
                </v-col>
                <v-col cols="6">
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="isGROF">
            <h3>{{ title }} GROF USA
                <v-btn color="#eabd75" v-if="dialogShopify" v-on:click="editItem(1)">
                    Add Variant
                    <v-icon>mdi-buffer</v-icon>
                </v-btn>
            </h3>
            
            <v-form
            ref="form"
            >
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="productGROF.name" :disabled="isProductShopify && productVariant" :rules="['Required']" label="Product Title"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" v-if="isProductShopify || newProduct">
                        <v-text-field v-model="productGROF.seo_title" :disabled="isProductShopify && productVariant || newProduct" label="{Product Title} | {Brand} | GROF USA (Automatic)"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="productGROF.variant_title" :rules="['Required']" label="Variant Title" type="text"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <v-text-field v-model="productGROF.brand_name" :disabled="isProductShopify" :rules="['Required']" label="Brand Ex: Kohler, Sterling..." type="text"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="isProductShopify || newProduct">
                        <v-text-field v-model="productGROF.product_type" :disabled="isProductShopify && productVariant" :rules="['Required']" label="Product Type Shopify"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <v-text-field v-model="productGROF.pricing_group" label="Pricing Group Ex: K10, S30... (Optional)" type="text"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <v-text-field v-model="productGROF.code" :disabled="isProductShopify" :rules="['Required']" label="Sku" type="text"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <v-text-field v-model="productGROF.barcode" label="Barcode (Optional)" type="text"></v-text-field>
                    </v-col>     
                    <v-col cols="12" sm="12" md="1">
                        <v-text-field v-model="productGROF.price_showroom_usd" :rules="['Required']" label="Price Showroom US$" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="1">
                        <v-text-field v-model="productGROF.price_ecommerce_usd" :rules="['Required']" label="Price Ecommerce US$" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="1">
                        <v-text-field v-model="productGROF.price_showroom_trade_usd" :rules="['Required']" label="Professional Price Ecommerce Trade US$" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <v-text-field v-model="productGROF.weight" label="Weight (lb)" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" v-if="isProductShopify || newProduct">
                        <v-text-field v-model="productGROF.tags" :disabled="isProductShopify && productVariant" label="Tags split for ','"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <v-select
                            :items="itemsCRITERIONS"
                            :rules="['Required']"
                            v-model="productGROF.criterion"
                            label="Select Criterion Unit"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" v-if="isProductShopify || newProduct">
                        <v-text-field v-model="productGROF.img_url" label="URL Image (Optional)" type="text"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            
            <div class="text-center">
                <v-btn text @click='cancel()'>Cancel</v-btn>
                <v-btn color="#eabd75" @click="saveProduct()" :disabled="disableSave">
                    Save
                    <v-icon>mdi-content-save-settings</v-icon>
                </v-btn>
                <v-btn color="#EBEBEB" @click="resetFormProduct()">Reset</v-btn>
            </div>
            
            <v-divider class="my-2"></v-divider>

            <v-row justify="space-around" v-if="productVariant">
                
                <v-col
                cols="12"
                sm="10"
                md="8"
                >
                <v-sheet
                    elevation="10"
                    class="py-4 px-4"
                >
                    <div class="text-h4 font-weight-bold grey--text text-center">
                        RELATED VARIANTS
                    </div>
                    <v-divider class="mx-4"></v-divider>
                    <v-chip-group
                    mandatory
                    >
                    <v-chip
                        v-for="variant in productGROF.variants"
                        :key="variant.id"
                        @click="getProductFromVariant(variant.id)"
                    >
                        {{ variant.variant_title }}
                    </v-chip>
                    </v-chip-group>
                </v-sheet>
                </v-col>
            </v-row>
            
            <v-row justify="center">
                <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <span class="headline font-weight-bold text-uppercase grey--text">New Variant</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <span class="title font-weight-bold text-uppercase grey--text">Basic Information</span>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.title" :rules="['Required']" label="Variant Title"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-select
                                            :items="itemsCRITERIONS"
                                            :rules="['Required']"
                                            v-model="newVariantGROF.criterion"
                                            label="Select Criterion Unit"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.code" :rules="['Required']" label="Sku"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.barcode" label="Barcode"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.pricing_group" label="Pricing Group Ex: K10, S30... (Optional)"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.price_showroom_usd" :rules="['Required']" label="Price Showroom US$" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.price_ecommerce_usd" :rules="['Required']" label="Price Ecommerce US$" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.price_showroom_trade_usd" :rules="['Required']" label="Professional Price Ecommerce Trade US$" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.weight" label="Weight (lb)" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantGROF.img_url" label="URL Image (Optional)" type="text"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click='close()'>Cancel</v-btn>
                                    <v-btn color="#eabd75" @click="saveNewVariant()">
                                        Save
                                        <v-icon> mdi-content-save-settings</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-container>

        <v-container v-if="isVIVES">
            <h3>{{ title }} VIVES by GROF
                <v-btn color="#eabd75" v-if="dialogShopify" v-on:click="editItem(1)">
                    Add Variant
                    <v-icon>mdi-buffer</v-icon>
                </v-btn>
            </h3>
            <v-form
            ref="form"
            >
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="productVIVES.name" :rules="['Required']" label="Product Title"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="isProductShopify || newProduct">
                    <v-text-field v-model="productVIVES.seo_title" :disabled="isProductShopify && productVariant || newProduct" label="{Product Title} | {Product Type} | VIVES by GROF (Automatic)"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="productVIVES.variant_title" :disabled="isProductShopify" :rules="['Required']" label="Variant Title" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="productVIVES.brand_name" :disabled="isProductShopify" :rules="['Required']" label="Brand Ex: Kohler, Sterling..." type="text"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" v-if="isProductShopify || newProduct">
                    <v-text-field v-model="productVIVES.product_type" :disabled="isProductShopify" :rules="['Required']" label="Product Type Shopify"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" v-if="isProductShopify || newProduct">
                    <v-text-field v-model="productVIVES.format_vives" :disabled="isProductShopify" :rules="['Required']" label="Product Format Ex:7.87x7.87"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" v-if="isProductShopify || newProduct">
                    <v-text-field v-model="productVIVES.finish_vives" :disabled="isProductShopify" :rules="['Required']" label="Product Finish Ex: Matte, Polished"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="productVIVES.pricing_group" label="Pricing Group Ex: K10, S30... (Optional)" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="productVIVES.code" :disabled="isProductShopify" :rules="['Required']" label="Sku" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="productVIVES.barcode" label="Barcode (Optional)" type="text"></v-text-field>
                </v-col>     
                <v-col cols="12" sm="12" md="1">
                    <v-text-field v-model="productVIVES.price_showroom_usd" :rules="['Required']" label="Price Showroom US$" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="1">
                    <v-text-field v-model="productVIVES.price_ecommerce_usd" :rules="['Required']" label="Price Ecommerce US$" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="1">
                    <v-text-field v-model="productVIVES.price_showroom_trade_usd" :rules="['Required']" label="Professional Price Ecommerce Trade US$" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="productVIVES.weight" label="Weight (lb)" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" v-if="isProductShopify || newProduct">
                    <v-text-field v-model="productVIVES.tags" label="Tags split for ','"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-select
                        :items="itemsCRITERIONS"
                        :rules="['Required']"
                        v-model="productVIVES.criterion"
                        label="Select Criterion Unit"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="isProductShopify || newProduct">
                    <v-text-field v-model="productVIVES.img_url" label="URL Image (Optional)" type="text"></v-text-field>
                </v-col>
            </v-row>
            <h4>Metafields</h4>
            <v-row>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="metafields_variant.pro_price_sqft" :rules="['Required']" label="Pro Price SQFT US($)" type="number"></v-text-field>
                </v-col>
                <!-- <v-col cols="6" sm="6" md="3">
                    <v-text-field v-model="metafields_variant.pro_price" :rules="['Required']" label="Pro Price US($)" type="number"></v-text-field>
                </v-col> -->
                <v-col cols="6" sm="6" md="3">
                    <v-text-field v-model="metafields_variant.price_show" :rules="['Required']" label="Shopify Price Display US($)" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="metafields_variant.pieces_per_box" :rules="['Required']" label="Pieces per Box" type="number"></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="metafields_variant.weight_per_box" :rules="['Required']" label="Weight per Box" type="number"></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="metafields_variant.boxes_per_pallet" :rules="['Required']" label="Boxes per Pallet" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="metafields_variant.weight_per_pallet" :rules="['Required']" label="Weight per Pallet" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="metafields_variant.sqft_per_box" :rules="['Required']" label="SQFT per Box" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="metafields_variant.selling_unit_show" :rules="['Required']" label="Selling Unit Show" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                    <v-select
                        :items="itemsVIVES"
                        v-model="metafields_variant.piece_or_box"
                        label="Select Piece or Box"
                        :rules="['Required']"
                        solo
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="metafields_variant.datasheetURL" label="URL Datasheet (Optional)" type="text"></v-text-field>
                </v-col>
            </v-row> 
            <div class="text-center">
                <v-btn text @click='cancel()'>Cancel</v-btn>
                <v-btn color="#eabd75" @click="saveProduct()">
                    Save
                    <v-icon> mdi-content-save-settings</v-icon>
                </v-btn>
                <v-btn color="#EBEBEB" @click="resetFormProduct()">Reset</v-btn>
            </div>
            </v-form>

            <v-row justify="space-around" v-if="productVariant">
                
                <v-col
                cols="12"
                sm="10"
                md="8"
                >
                <v-sheet
                    elevation="10"
                    class="py-4 px-4"
                >
                    <div class="text-h4 font-weight-bold grey--text text-center">
                        RELATED VARIANTS
                    </div>
                    <v-divider class="mx-4"></v-divider>
                    <v-chip-group
                    mandatory
                    >
                        <v-chip
                            v-for="variant in productVIVES.variants"
                            :key="variant.id"
                            @click="getProductFromVariant(variant.id)"
                        >
                            {{ variant.variant_title }}
                        </v-chip>
                    </v-chip-group>
                </v-sheet>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-dialog v-model="dialog" max-width="1000px">
                    <v-card>
                        <v-card-title>
                            <span class="headline font-weight-bold text-uppercase grey--text">New Variant</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <span class="title font-weight-bold text-uppercase grey--text">Basic Information</span>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.title" :rules="['Required']" label="Variant Title"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-select
                                            :items="itemsCRITERIONS"
                                            :rules="['Required']"
                                            v-model="newVariantVIVES.criterion"
                                            label="Select Criterion Unit"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.code" :rules="['Required']" label="Sku"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.barcode" label="Barcode"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.pricing_group" label="Pricing Group Ex: K10, S30... (Optional)"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.price_showroom_usd" :rules="['Required']" label="Price Showroom US$" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.price_ecommerce_usd" :rules="['Required']" label="Price Ecommerce US$" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.price_showroom_trade_usd" :rules="['Required']" label="Professional Price Ecommerce Trade US$" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field v-model="newVariantVIVES.format_vives" :rules="['Required']" label="Product Format Ex:7.87x7.87"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field v-model="newVariantVIVES.finish_vives" :rules="['Required']" label="Product Finish Ex: Matte, Polished"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.weight" label="Weight (lb)" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="newVariantVIVES.img_url" label="URL Image (Optional)" type="text"></v-text-field>
                                    </v-col>
                                </v-row>
                                <h4>Metafields</h4>
                                <v-row>
                                    <v-col cols="6" sm="6" md="3">
                                        <v-text-field v-model="metafields_variant_new.pro_price_sqft" :rules="['Required']" label="Pro Price SQFT US($)" type="number"></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="6" sm="6" md="3">
                                        <v-text-field v-model="metafields_variant_new.pro_price" :rules="['Required']" label="Pro Price US($)" type="number"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="6" sm="6" md="3">
                                        <v-text-field v-model="metafields_variant_new.price_show" :rules="['Required']" label="Shopify Price Display US($)" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="metafields_variant_new.pieces_per_box" :rules="['Required']" label="Pieces per Box" type="number"></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="metafields_variant_new.weight_per_box" :rules="['Required']" label="Weight per Box" type="number"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="metafields_variant_new.boxes_per_pallet" :rules="['Required']" label="Boxes per Pallet" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="metafields_variant_new.weight_per_pallet" :rules="['Required']" label="Weight per Pallet" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="metafields_variant_new.sqft_per_box" :rules="['Required']" label="SQFT per Box" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-select
                                            :items="itemsVIVES"
                                            v-model="metafields_variant_new.piece_or_box"
                                            label="Select Piece or Box"
                                            :rules="['Required']"
                                            solo
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="metafields_variant_new.selling_unit_show" :rules="['Required']" label="Selling Unit Show" type="text"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="12">
                                        <v-text-field v-model="metafields_variant_new.datasheetURL" label="URL Datasheet (Optional)" type="text"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click='close()'>Cancel</v-btn>
                                    <v-btn color="#eabd75" @click="saveNewVariant()">
                                        Save
                                        <v-icon> mdi-content-save-settings</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-container>

    </v-app>
</template>


<script>
    export default {
        name: "ManageProducts",
        props:['id'],
        

        data() {
            return {
                shop:null,
                newProduct:true,
                title: 'NEW PRODUCT',
                isGROF:false,
                isVIVES:false,
                isProductShopify:false,
                productVariant:false,
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
                loading:false,
                dialog: false,
                dialogShopify: false,
                disableSave: false,
                itemsCRITERIONS:[],
                itemsVIVES:[
                    {text: "Select Piece or Box", value: null},
                    {text: "Box", value: 'Box'},
                    {text: "Piece", value: 'Piece'},
                ],
                shopList:[
                    {text: "Select", value: null},
                    {text: "GROF USA", value: "GROF"},
                    {text: "VIVES by GROF", value: "VIVES"},
                ],
                metafields_variant: {
                    pro_price_sqft:'',
                    pro_price:'',
                    pieces_per_box:'',
                    weight_per_box:'',
                    boxes_per_pallet:'',
                    weight_per_pallet:'',
                    sqft_per_box:'',
                    piece_or_box:'',
                    selling_unit_show:'SQFT',
                    price_show:'',
                    datasheetURL:''
                },
                metafields_variant_new: {
                    pro_price_sqft:'',
                    pro_price:'',
                    pieces_per_box:'',
                    weight_per_box:'',
                    boxes_per_pallet:'',
                    weight_per_pallet:'',
                    sqft_per_box:'',
                    piece_or_box:'',
                    selling_unit_show:'SQFT',
                    price_show:'',
                    datasheetURL:''
                },
                productGROF: {
                    name:'',
                    variant_title:'',
                    code:'',
                    barcode:'',
                    product_type:'',
                    pricing_group:'',
                    price_showroom_usd:'',
                    price_ecommerce_usd:'',
                    price_showroom_trade_usd:'',
                    weight:'',
                    brand:'',
                    criterion:'',
                    
                    // METAFIELDS & SHOPIFY
                    seo_title:'',
                    tags:'',
                    img_url:'',
                    seo_description:'',
                    variants: [],
                },
                productVIVES: {
                    name:'',
                    variant_title:'',
                    code:'',
                    barcode:'',
                    format_vives:'',
                    finish_vives:'',
                    product_type:'',
                    pricing_group:'',
                    price_showroom_usd:'',
                    price_ecommerce_usd:'',
                    price_showroom_trade_usd:'',
                    weight:'',
                    brand:'',
                    criterion:'',
                    
                    // METAFIELDS & SHOPIFY
                    seo_title:'',
                    tags:'',
                    img_url:'',
                    seo_description:'',
                    variants: [],
                },
                newVariantGROF: {
                    variant_title:'',
                    code:'',
                    barcode:'',
                    pricing_group:'',
                    price_showroom_usd:'',
                    price_ecommerce_usd:'',
                    price_showroom_trade_usd:'',
                    weight:'',
                    criterion:'',
                    img_url:''
                },
                newVariantVIVES: {
                    variant_title:'',
                    code:'',
                    barcode:'',
                    format_vives:'',
                    finish_vives:'',
                    pricing_group:'',
                    price_showroom_usd:'',
                    price_ecommerce_usd:'',
                    price_showroom_trade_usd:'',
                    weight:'',
                    criterion:'',
                    img_url:'',
                },
            }
        },

        mounted() {
            axios.get('/api/products').then(res => {
                var criterions = res.data.criterions;
                criterions.forEach(criterion => {
                    if (criterion.name == 'Quantity') {
                        this.itemsCRITERIONS.push({text: criterion.name+' (usually)', value: criterion.name});
                    } else {
                        this.itemsCRITERIONS.push({text: criterion.name, value: criterion.name});
                    }
                });
            });

            if(this.$route.params.id){
                this.productId = this.$route.params.id;
                this.title = 'EDIT PRODUCT';
                this.newProduct = false;
                console.log(this.productId);
                axios.get('/api/products/'+this.productId).then(res => {
                    //this.product = res.data.product;
                    //console.log(this.product.source);
                    if (res.data.product.shopify_product_id){
                        this.dialogShopify = true;
                    }
                    if (res.data.product.source == 'GROF') {
                        this.productGROF = res.data.product;
                        this.title = 'EDIT PRODUCT '+this.productGROF.variant_title;
                        if(this.productGROF.shopify_product_id != null) {
                            this.isProductShopify = true;
                            if (res.data.variants.length != 1) {
                                this.productGROF.variants = res.data.variants;
                                this.productVariant = true;
                            }
                        }
                        this.isGROF=true;
                        this.isVIVES=false;
                    } else {
                        this.productVIVES = res.data.product;
                        this.title = 'EDIT PRODUCT '+this.productVIVES.variant_title;
                        console.log(this.productVIVES);
                        if(this.productVIVES.shopify_product_id != null) {
                            this.isProductShopify = true;
                            var arrayDataVives = res.data.product.variant_title.split("/");
                            this.productVIVES.variant_title = arrayDataVives[0] ? arrayDataVives[0] : res.data.product.variant_title;
                            this.productVIVES.format_vives = arrayDataVives[1] ? arrayDataVives[1] : '';
                            this.productVIVES.finish_vives = arrayDataVives[2] ? arrayDataVives[2] : '';
                            //console.log(arrayDataVives);
                            console.log(res.data.variants);
                            if (res.data.variants.length != 1) {
                                this.productVIVES.variants = res.data.variants;
                                this.productVariant = true;
                            }
                            if(res.data.product.metafields_variant != null) {
                                var metafields_load = JSON.parse(res.data.product.metafields_variant);
                                metafields_load.metafields.forEach(element => {
                                    switch (element.key) {
                                        case 'pro_price_sqft':
                                            this.metafields_variant.pro_price_sqft = element.value/1000;
                                            break;
                                        case 'pro_price':
                                            this.metafields_variant.pro_price = element.value/1000;
                                            break;
                                        case 'pieces_per_box':
                                            this.metafields_variant.pieces_per_box = element.value;
                                            break;
                                        case 'weight_per_box':
                                            this.metafields_variant.weight_per_box = element.value/1000;
                                            break;
                                        case 'boxes_per_pallet':
                                            this.metafields_variant.boxes_per_pallet = element.value;
                                            break;
                                        case 'weight_per_pallet':
                                            this.metafields_variant.weight_per_pallet = element.value/1000;
                                            break;
                                        case 'sqft_per_box':
                                            this.metafields_variant.sqft_per_box = element.value/1000;
                                            break;
                                        case 'selling_unit_show':
                                            this.metafields_variant.selling_unit_show = element.value;
                                            break;
                                        case 'piece_or_box':
                                            this.metafields_variant.piece_or_box = element.value;
                                            break;
                                        case 'price_show':
                                            this.metafields_variant.price_show = element.value/1000;
                                            break;
                                        case 'datasheet':
                                            this.metafields_variant.datasheetURL = element.value;
                                            break;
                                    }
                                });
                            }
                        }
                        this.isGROF=false;
                        this.isVIVES=true;
                    }
                });
            }
        },

        computed: {
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
        },


        watch: {
            shop:function(val) {
                this.shop = val;
                console.log(this.shop);
                if(this.shop === 'GROF'){
                    this.isGROF = true;
                    this.isVIVES = false;
                }
                else if(this.shop === 'VIVES'){
                    this.isGROF = false;
                    this.isVIVES = true;
                }
                else{
                    this.isGROF = false;
                    this.isVIVES = false;
                }
            },
        },


        methods: {
            resetFormProduct () {
                this.$refs.form.reset()
            },
            
            cancel(){
                this.$router.push('/products');
            },

            close(){
                this.dialog = false;
            },

            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },

            editItem: function (item) {
                this.dialog = true;
                //this.brandId = item.id;
                //this.newVariant = item;
            },

            saveNewVariant(){
                this.loading = true;
                this.disableSave = true;
                //this.dialog = false;
                if(this.newVariantGROF.title){
                    console.log(this.productGROF);
                    var variantNew = this.newVariantGROF;
                    axios.post('/api/products/variants', {
                        'variant_title':variantNew.title,
                        'code':variantNew.code,
                        'barcode':variantNew.barcode,
                        'price_showroom_usd':variantNew.price_showroom_usd,
                        'price_ecommerce_usd':variantNew.price_ecommerce_usd,
                        'price_showroom_trade_usd':variantNew.price_showroom_trade_usd,
                        'weight':variantNew.weight,
                        'criterion':variantNew.criterion,
                        'pricing_group':variantNew.pricing_group,
                        'shopify_product_id':this.productGROF.shopify_product_id,
                        'img_url':variantNew.img_url,
                        'shop_module':'GROF'
                    }).then(response => {
                        this.loading = false;
                        let res = response;
                        this.activateSnackbar(`You have successfully operation. ${res.data.message}`, 'success')
                        location.reload();
                    }).catch(error=> {
                        console.log(error);
                        this.activateSnackbar(`error`, 'error')
                        this.loading = false;
                    });
                } else {
                    var variantNew = this.newVariantVIVES;
                    console.log(this.metafields_variant_new);
                    console.log(variantNew);
                    axios.post('/api/products/variants', {
                        'variant_title':variantNew.title,
                        'code':variantNew.code,
                        'barcode':variantNew.barcode,
                        'format_vives':variantNew.format_vives,
                        'finish_vives':variantNew.finish_vives,
                        'price_showroom_usd':variantNew.price_showroom_usd,
                        'price_ecommerce_usd':variantNew.price_ecommerce_usd,
                        'price_showroom_trade_usd':variantNew.price_showroom_trade_usd,
                        'weight':variantNew.weight,
                        'img_url':variantNew.img_url,
                        'price_show':this.metafields_variant_new.price_show,
                        'pro_price_sqft':this.metafields_variant_new.pro_price_sqft,
                        'pieces_per_box':this.metafields_variant_new.pieces_per_box,
                        'weight_per_box':this.metafields_variant_new.weight_per_box,
                        'boxes_per_pallet':this.metafields_variant_new.boxes_per_pallet,
                        'weight_per_pallet':this.metafields_variant_new.weight_per_pallet,
                        'sqft_per_box':this.metafields_variant_new.sqft_per_box,
                        'piece_or_box':this.metafields_variant_new.piece_or_box,
                        'selling_unit_show':this.metafields_variant_new.selling_unit_show,
                        'datasheetURL':this.metafields_variant_new.datasheetURL,
                        'shopify_product_id':this.productVIVES.shopify_product_id,
                        'shop_module':'VIVES'
                    }).then(response => {
                        this.loading = false;
                        let res = response;
                        this.activateSnackbar(`You have successfully operation. ${res.data.message}`, 'success')
                        location.reload();
                    }).catch(error=> {
                        console.log(error);
                        this.activateSnackbar(`error`, 'error')
                        this.loading = false;
                    });
                }
            },

            saveProduct() {
                this.disableSave = true;
                if(this.productId === undefined) {
                    this.loading = true;
                    var data_product = {};
                    if (this.isGROF){
                        var pricing_group_upd = this.productGROF.pricing_group ? this.productGROF.pricing_group : '';
                        var productNew = this.productGROF;
                        data_product = {   
                            'name':productNew.name,
                            'variant_title':productNew.variant_title,
                            'code':productNew.code,
                            'barcode':productNew.barcode,
                            'product_type':productNew.product_type,
                            'price_showroom_usd':productNew.price_showroom_usd,
                            'price_ecommerce_usd':productNew.price_ecommerce_usd,
                            'price_showroom_trade_usd':productNew.price_showroom_trade_usd,
                            'weight':productNew.weight,
                            'brand':productNew.brand_name,
                            'criterion':productNew.criterion,
                            'pricing_group':productNew.pricing_group,
                            
                            // METAFIELDS & SHOPIFY
                            'seo_title':productNew.seo_title,
                            'tags':productNew.tags,
                            'img_url':productNew.img_url,
                            
                            'from_module_gsrp':'TRUE',
                            'shop_module':'GROF'
                        }
                    } else {
                        var productNew = this.productVIVES;
                        var pricing_group_upd = this.productVIVES.pricing_group ? this.productVIVES.pricing_group : '';
                        data_product = {   
                            'name':productNew.name,
                            'variant_title':productNew.variant_title,
                            'code':productNew.code,
                            'barcode':productNew.barcode,
                            'format_vives':productNew.format_vives,
                            'finish_vives':productNew.finish_vives,
                            'product_type':productNew.product_type,
                            'price_showroom_usd':productNew.price_showroom_usd,
                            'price_ecommerce_usd':productNew.price_ecommerce_usd,
                            'price_showroom_trade_usd':productNew.price_showroom_trade_usd,
                            'weight':productNew.weight,
                            'brand':productNew.brand_name,
                            'criterion':productNew.criterion,
                            'pricing_group':productNew.pricing_group,
                            
                            // METAFIELDS & SHOPIFY
                            'seo_title':productNew.seo_title,
                            'tags':productNew.tags,
                            'img_url':productNew.img_url,
                            'price_show':this.metafields_variant.price_show,
                            'pro_price_sqft':this.metafields_variant.pro_price_sqft,
                            'pieces_per_box':this.metafields_variant.pieces_per_box,
                            //'weight_per_box':this.metafields_variant.weight_per_box,
                            'boxes_per_pallet':this.metafields_variant.boxes_per_pallet,
                            'weight_per_pallet':this.metafields_variant.weight_per_pallet,
                            'sqft_per_box':this.metafields_variant.sqft_per_box,
                            'piece_or_box':this.metafields_variant.piece_or_box,
                            'selling_unit_show':this.metafields_variant.selling_unit_show,
                            'datasheetURL':this.metafields_variant.datasheetURL,
                            
                            'from_module_gsrp':'TRUE',
                            'shop_module':'VIVES'
                        }
                    }
                    console.log(productNew);
                    axios.post('/api/products', data_product)
                    .then(response => {
                        this.loading = false;
                        let res = response;
                        this.activateSnackbar(`You have successfully operation. ${res.data.message}`, 'success')
                        this.$router.push(`/products`)
                    })
                    .catch(error=> {
                        this.loading = false;
                        this.disableSave = false;
                        console.log(error);
                        this.activateSnackbar(`error, please check the information`, 'error');
                    });
                } else {
                    this.loading = true;
                    if (this.isGROF){
                        var productUpdate = this.productGROF; // if vives or grof
                        var criterion_upd = this.productGROF.criterion ? this.productGROF.criterion : 'unit';
                        var pricing_group_upd = this.productGROF.pricing_group ? this.productGROF.pricing_group : '';
                        console.log(productUpdate);
                        axios.put('/api/products/' + this.productId, 
                            {   
                                'id':productUpdate.id,
                                'name':productUpdate.name,
                                'variant_title':productUpdate.variant_title,
                                'code':productUpdate.code,
                                'barcode':productUpdate.barcode,
                                'price_showroom_usd':productUpdate.price_showroom_usd,
                                'price_ecommerce_usd':productUpdate.price_ecommerce_usd,
                                'price_showroom_trade_usd':productUpdate.price_showroom_trade_usd,
                                'weight':productUpdate.weight,
                                'brand':productUpdate.brand_name,
                                'criterion':criterion_upd,
                                'pricing_group':pricing_group_upd,
                                'product_type':productUpdate.product_type,
                                
                                // METAFIELDS & SHOPIFY
                                'seo_title':productUpdate.seo_title,
                                'tags':productUpdate.tags,
                                'img_url':productUpdate.img_url,
                                
                                'from_module_gsrp':'TRUE',
                                'shop_module':'GROF'
                            }).then(response => {
                                this.loading = false;
                                let res = response;
                                this.activateSnackbar(`You have successfully edited the product`, 'success')
                                location.reload();
                            }).catch(error=> {
                                this.loading = false;
                                this.disableSave = false;
                                console.log(error);
                                this.activateSnackbar(`error`, 'error');
                            });
                    } else {
                        var productUpdate = this.productVIVES; // if vives or grof
                        var criterion_upd = this.productVIVES.criterion ? this.productVIVES.criterion : 'unit';
                        var pricing_group_upd = this.productVIVES.pricing_group ? this.productVIVES.pricing_group : '';
                        console.log(productUpdate);
                        axios.put('/api/products/' + this.productId, 
                            {   
                                'id':productUpdate.id,
                                'name':productUpdate.name,
                                'variant_title':productUpdate.variant_title,
                                'code':productUpdate.code,
                                'barcode':productUpdate.barcode,
                                'format_vives':productUpdate.format_vives,
                                'finish_vives':productUpdate.finish_vives,
                                'price_showroom_usd':productUpdate.price_showroom_usd,
                                'price_ecommerce_usd':productUpdate.price_ecommerce_usd,
                                'price_showroom_trade_usd':productUpdate.price_showroom_trade_usd,
                                'weight':productUpdate.weight,
                                'brand':productUpdate.brand_name,
                                'criterion':criterion_upd,
                                'pricing_group':pricing_group_upd,
                                'product_type':productUpdate.product_type,
                                
                                // METAFIELDS & SHOPIFY VIVES
                                'seo_title':productUpdate.seo_title,
                                'tags':productUpdate.tags,
                                'img_url':productUpdate.img_url,
                                'price_show':this.metafields_variant.price_show,
                                'pro_price_sqft':this.metafields_variant.pro_price_sqft,
                                'pieces_per_box':this.metafields_variant.pieces_per_box,
                                'weight_per_box':this.metafields_variant.weight_per_box,
                                'boxes_per_pallet':this.metafields_variant.boxes_per_pallet,
                                'weight_per_pallet':this.metafields_variant.weight_per_pallet,
                                'sqft_per_box':this.metafields_variant.sqft_per_box,
                                'piece_or_box':this.metafields_variant.piece_or_box,
                                'selling_unit_show':this.metafields_variant.selling_unit_show,
                                'datasheetURL':this.metafields_variant.datasheetURL,
                                
                                'from_module_gsrp':'TRUE',
                                'shop_module':'VIVES'
                            }).then(response => {
                                this.loading = false;
                                let res = response;
                                this.activateSnackbar(`You have successfully edited the product`, 'success')
                                location.reload();
                            }).catch(error=> {
                                this.loading = false;
                                this.disableSave = false;
                                console.log(error);
                                this.activateSnackbar(`error`, 'error');
                            });
                    }
                    
                }
            },

            getProductFromVariant (val) {
                this.productId = val;
                this.title = 'EDIT PRODUCT';
                this.newProduct = false;
                console.log(this.productId);
                if (this.isGROF){
                    axios.get('/api/products/'+this.productId).then(res => {
                    this.productGROF = res.data.product;
                    this.title = 'EDIT PRODUCT '+ this.productGROF.variant_title;
                    if(this.productGROF.shopify_product_id != null) {
                        this.isProductShopify = true;
                        if (res.data.variants.length != 1) {
                            this.productGROF.variants = res.data.variants;
                            this.productVariant = true;
                        }
                    }
                    });
                } else {
                    axios.get('/api/products/'+this.productId).then(res => {
                    this.productVIVES = res.data.product;
                    this.title = 'EDIT PRODUCT '+ this.productVIVES.variant_title;
                    console.log(this.productVIVES);
                    var arrayDataVives = res.data.product.variant_title.split("/");
                    this.productVIVES.variant_title = arrayDataVives[0] ? arrayDataVives[0] : res.data.product.variant_title;
                    this.productVIVES.format_vives = arrayDataVives[1] ? arrayDataVives[1] : '';
                    this.productVIVES.finish_vives = arrayDataVives[2] ? arrayDataVives[2] : '';
                    if(this.productVIVES.shopify_product_id != null) {
                        this.isProductShopify = true;
                        if (res.data.variants.length != 1) {
                            this.productVIVES.variants = res.data.variants;
                            this.productVariant = true;
                        }
                        if(res.data.product.metafields_variant != null) {
                            var metafields_load = JSON.parse(res.data.product.metafields_variant);
                            metafields_load.metafields.forEach(element => {
                                switch (element.key) {
                                    case 'pro_price_sqft':
                                        this.metafields_variant.pro_price_sqft = element.value/1000;
                                        break;
                                    case 'pro_price':
                                        this.metafields_variant.pro_price = element.value/1000;
                                        break;
                                    case 'pieces_per_box':
                                        this.metafields_variant.pieces_per_box = element.value;
                                        break;
                                    case 'weight_per_box':
                                        this.metafields_variant.weight_per_box = element.value/1000;
                                        break;
                                    case 'boxes_per_pallet':
                                        this.metafields_variant.boxes_per_pallet = element.value;
                                        break;
                                    case 'weight_per_pallet':
                                        this.metafields_variant.weight_per_pallet = element.value/1000;
                                        break;
                                    case 'sqft_per_box':
                                        this.metafields_variant.sqft_per_box = element.value/1000;
                                        break;
                                    case 'selling_unit_show':
                                        this.metafields_variant.selling_unit_show = element.value;
                                        break;
                                    case 'piece_or_box':
                                        this.metafields_variant.piece_or_box = element.value;
                                        break;
                                    case 'price_show':
                                        this.metafields_variant.price_show = element.value/1000;
                                        break;
                                    case 'datasheet':
                                        this.metafields_variant.datasheetURL = element.value;
                                        break;
                                }
                            });
                        } else {
                            this.metafields_variant.pro_price_sqft = "";
                            this.metafields_variant.pro_price = "";
                            this.metafields_variant.pieces_per_box = "";
                            this.metafields_variant.weight_per_box = "";
                            this.metafields_variant.boxes_per_pallet = "";
                            this.metafields_variant.weight_per_pallet = "";
                            this.metafields_variant.sqft_per_box = "";
                            this.metafields_variant.selling_unit_show = "";
                            this.metafields_variant.piece_or_box = "";
                            this.metafields_variant.price_show = "";
                            this.metafields_variant.datasheetURL = "";
                        }
                    }
                    });
                }
                
            },

        },
    }
</script>


<style scoped>
</style>

