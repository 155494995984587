<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                        @keydown.enter="searchQuery"
                        @click:clear="getList"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-data-table
            :headers="headers"
            show-expand
            :items="returns"
            item-key="id"
            :search="search"
            :sort-by="['created_at']"
            :sort-desc="[true]"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            loading-text="Loading... Please wait"
        >
            <template v-slot:item.created_at="{ item, header, value }">
                <date-component :registration="value"></date-component>
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip
                    class="chip-overflow"
                    :color="getColor(item.status)"
                    dark
                    >{{ item.status }}</v-chip
                >
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-simple-table class="test">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Code</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Total Price</th>
                                    <th>Observations</th>
                                    <th>Images</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in item.sale_return_details">
                                    <td>
                                        <v-btn
                                            text
                                            class="text-none font-weight-regular"
                                            >{{ product.name }}</v-btn
                                        >
                                    </td>
                                    <td>{{ product.code }}</td>
                                    <td>{{ product.quantity }}</td>
                                    <td>{{ product.unit_price }}</td>
                                    <td>
                                        {{
                                            product.unit_price *
                                                product.quantity
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            product.description !== null
                                                ? product.description
                                                : ""
                                        }}
                                    </td>
                                    <td>
                                        <!--Images-->
                                        <v-list-item-avatar
                                            v-for="image in product.sale_return_images_details"
                                            :key="image.id"
                                            :horizontal="true"
                                        >
                                            <v-btn
                                                :to="`${image.picture}`"
                                                target="_blank"
                                            >
                                                <img
                                                    :src="`${image.picture}`"
                                                    style="width: 50px; height: 50px"
                                                />
                                            </v-btn>
                                        </v-list-item-avatar>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </td>
            </template>

            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon
                        small
                        class="mr-2"
                        v-if="item.status != 'Approved' && item.status != 'Incomplete' && item.status != 'Denied'"
                        v-on:click="openSendConfirmation(item)"
                    >
                        mdi-check-bold
                    </v-icon>
                    <v-icon small v-on:click="editItem(item, 'Incomplete')" v-if="item.status != 'Incomplete'">
                        mdi-pencil
                    </v-icon>
                    <v-icon small v-on:click="editItem(item, 'Denied')" v-if="item.status != 'Approved' && item.status != 'Denied'">
                        mdi-cancel
                    </v-icon>
                    <v-icon small v-on:click="openNotification(item)">
                        mdi-delete
                    </v-icon>
                </td>
            </template>

            <template v-slot:top>
                <v-dialog v-model="dialog_incomplete" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <span
                                class="headline font-weight-bold text-uppercase grey--text"
                                >Alert</span
                            >
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <span class="subtitle-1"
                                            >Are you sure you want to change this
                                            status to Incomplete?</span
                                        >
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="10"
                                        >
                                            <v-text-field
                                                v-model="incomplete_message"
                                                label="Reason for Incomplete"
                                            ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="save(1, incomplete_message)"
                                >Save</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="close"
                                >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog_denied" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <span
                                class="headline font-weight-bold text-uppercase grey--text"
                                >Alert</span
                            >
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <span class="subtitle-1"
                                            >Are you sure you want to change this
                                            status to Denied and send email to user?</span
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="10"
                                    >
                                    <v-combobox
                                        v-model="select_denied"
                                        :items="items_denied"
                                        label="Reasons"
                                        hide-selected
                                        solo
                                        v-on:change="onChangeAllowDeniedMessage(select_denied)"
                                    ></v-combobox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="10"
                                    >
                                        <v-text-field
                                            v-model="denied_message"
                                            label="Reason for Deny"
                                            v-if="allow_denied_message"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="save(2, denied_message, select_denied.value)"
                                >Save</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="close"
                                >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="notification" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span
                                class="headline font-weight-bold text-uppercase grey--text"
                                >Alert</span
                            >
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-col cols="12">
                                    <span class="subtitle-1"
                                        >Are you sure you want to delete this
                                        Return?</span
                                    >
                                </v-col>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItem"
                                >Accept</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="close"
                                >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-data-table>

        <v-dialog v-model="sendEmailConfirmation" max-width="500px">
            <v-card>
                <v-card-title>
                    <span
                        class="headline font-weight-bold text-uppercase grey--text"
                        >Alert</span
                    >
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-col cols="12">
                            <span class="subtitle-1"
                                >Are you sure you want to approve this return
                                and send an approval mail to the user of this
                                request?</span
                            >
                        </v-col>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="save(3)"
                        >Accept</v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEmailApproved"
                        >Cancel</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="text-center" style="padding: 15px 0;" v-if="lastPage !== 0">
            <v-pagination
                v-model="currentPage"
                color="#eabd75"
                :length="lastPage"
                :total-visible="8"
            ></v-pagination>
        </div>
    </v-app>
</template>

<script>
import DateComponent from "../../components/DateComponent";

export default {
    name: "Dashboard",
    components: { DateComponent },

    data() {
        return {
            search: "",
            headers: [
                { text: "", value: "data-table-expand" },
                {
                    text: "Customer",
                    align: "start",
                    value: "first_name"
                },
                { text: "Shop", value: "shop_id" },
                { text: "Reference", value: "reference" },
                { text: "Phone", value: "phone" },
                { text: "Email", value: "email" },
                { text: "Total USD", value: "total" },
                { text: "Status", value: "status" },
                { text: "Initials", value: "initials" },
                { text: "Dispatched", value: "dispatched_date" },
                { text: "Date", value: "created_at" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            roles: [],
            benefitSelected: null,
            sendEmailConfirmation: false,
            manualBenefits: [],
            possibleBenefits: [],
            returns: [],
            plan_id: null,
            search2: null,
            dialog_incomplete: false,
            dialog_denied: false,
            denied_message: null,
            select_denied: null,
            items_denied: [
                { text: "Images don’t correspond to the products you want to return.", value: 0 },
                { text: "Boxes are not in resealable conditions.", value: 1 },
                { text: "Product was clearly installed (Please referred to Manufacturer Guaranty Policy)", value: 2 },
                { text: "Product was broken after dispatched.", value: 3 },
                { text: "Other", value: 4 },
            ],
            allow_denied_message: false,
            incomplete_message: null,
            saveButton: true,
            roleValue: null,
            transactions: [],
            loading: true,
            notification: false,
            loadingDialog: false,
            currentPage: 0,
            lastPage: 0,
            pageNumber: 0
        };
    },

    watch: {
        currentPage(newVal) {
            if (this.search == "" || this.search == null) {
                this.getList(newVal);
            } else {
                this.searchQuery(newVal);
            }
        }
    },

    computed: {},

    mounted() {
        axios.get("/api/returns").then(res => {
            this.response = res.data.sale_returns.data;
            this.returns = [];
            for (let sale_return of this.response) {
                this.returns.push(sale_return);
            }
            this.currentPage = res.data.sale_returns.current_page;
            this.lastPage = res.data.sale_returns.last_page;
            this.loading = false;
        });
    },

    methods: {
        getColor: function(status) {
            if (status === "Approved") return "blue";
            else if (status === "Complete") return "green";
            else if (status === "Denied") return "red";
            else return "";
        },

        onChangeAllowDeniedMessage(select_denied) {
            if (select_denied.value == 4) {
                this.allow_denied_message = true;
            } else {
                this.allow_denied_message = false;
            }
        },

        openNotification: function(item) {
            this.notification = true;
            this.deletedItemId = item.id;
        },

        openDialog: function() {
            this.dialog_incomplete = true;
        },

        editItem: function(item, type) {
            if (type == "Incomplete") {
                this.dialog_incomplete = true;
            } else if (type == "Denied") {
                this.dialog_denied = true;
            }
            this.modifyItem = item;
        },

        openSendConfirmation: function(item) {
            this.sendEmailConfirmation = true;
            this.modifyItem = item;
        },

        closeEmailApproved() {
            this.sendEmailConfirmation = false;
            this.invitedItemId = -1;
        },

        required(value) {
            if (value instanceof Array && value.length == 0) {
                return "Required.";
            }
            return !!value || "Required.";
        },

        close() {
            this.dialog_incomplete = false;
            this.dialog_denied = false;
            this.notification = false;
            this.deletedItemId = -1;
        },

        deleteItem: function() {
            axios
                .delete("/api/returns/" + this.deletedItemId)
                .then(response => {
                    let res = response;
                    console.log(response);
                    this.activateSnackbar(
                        `You have successfully deleted the contact information`,
                        "success"
                    );
                })
                .catch(error => {
                    console.log(error);
                    this.activateSnackbar(`Error`, "error");
                });

            this.close();
            this.getList();
        },

        save(type, message=null, denied_option=null) {
            console.log(type, message);
            this.loading = true;
            axios
                .put("/api/returns/" + this.modifyItem.id, {
                    type: type,
                    message: message ? message : null,
                    denied_option: denied_option ? denied_option : null
                })
                .then(response => {
                    let res = response;
                    this.loadingDialog = false;
                    this.loading = false;
                })
                .catch(error => {
                    this.closeDialog();
                });
            this.closeDialog();
            this.closeEmailApproved();
            this.getList();
        },

        getList(pageNumber) {
            axios.get("/api/returns?page=" + pageNumber).then(res => {
                this.loading = false;
                this.returns = res.data.sale_returns.data;
                this.currentPage = res.data.sale_returns.current_page;
                this.lastPage = res.data.sale_returns.last_page;
            });
        },

        searchQuery(pageNumber = 0) {
            if (this.search != "") {
                this.loading = true;
                axios
                    .get("api/search-sale-returns?page=" + pageNumber, {
                        params: { query: this.search }
                    })
                    .then(res => {
                        this.returns = res.data.sale_returns.data;
                        this.currentPage = res.data.sale_returns.current_page;
                        this.lastPage = res.data.sale_returns.last_page;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },

        closeDialog: function() {
            this.dialog_incomplete = false;
            this.dialog_denied = false;
            this.incomplete_message = null;
            this.denied_message = null;
        }
    }
};
</script>

<style scoped>
::v-deep tbody td {
    cursor: pointer;
}

.container {
    max-width: 100vw;
}

::v-deep tbody td td.non-clickable {
    cursor: auto;
}
.chip-overflow {
    max-width: 90px;
    display: block;
    text-align: center;
}
</style>
