<template>
    <v-app>
        <v-card-title>
            <span class="headline font-weight-bold text-uppercase grey--text">{{title}}</span>
        </v-card-title>
        <v-card color="#FCFCFC" class="mb-12" height="300px">
            <v-container>
                <span class="title font-weight-bold text-uppercase grey--text">Basic Information</span>
                <v-row>
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field v-model="name" label="Name"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select
                                v-model="criterionsSelected"
                                :items="criterions"
                                item-text="name"
                                item-value="id"
                                label="Criterion"
                                return-object
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field v-model="reward_points" label="Reward points"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field v-model="unit_measure" label="Unit measure"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn text @click='cancel(false)'>Cancel</v-btn>
                    <v-btn color="#eabd75" @click="save()">
                        Save
                        <v-icon> mdi-content-save-settings</v-icon>
                    </v-btn>
                </v-row>
                <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
                    {{ snackbartext }}
                    <v-btn dark text @click="snackbar = false">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-container>
        </v-card>

    </v-app>
</template>
<script>
    export default {
        name: "ManageBrands",
        props:['brandId'],

        data: () => ({
            snackbar: false,
            snackbartext: '',
            snackbarcolor: '',
            snack: false,
            snackColor: '',
            snackText: '',
            name:'',
            reward_points: 0,
            unit_measure: '',
            criterions:[],
            criterionsSelected: { id: 0, name: '',  description: ''},
            title: 'New Brand',
        }),
        mounted() {
            var criterion_type = 'product';
            axios.get('/api/criterions/' + criterion_type).then(res => {
                this.criterions=res.data.criterions;
            });

            if(this.brandId === undefined) {

            }else{
                this.title = 'Edit Brand';
                axios.get('/api/brands/'+this.brandId).then(res => {
                    this.name=res.data.brand.name;
                    this.reward_points=res.data.brand.reward_points;
                    this.unit_measure=res.data.brand.unit_measure;
                    this.criterionsSelected={id : res.data.brand.criterion.id, name: res.data.brand.criterion.name, description: res.data.brand.criterion.description};
                });
            }

        },
        methods:{
            save() {
                if(this.brandId === undefined) {

                }else{
                    axios.put('/api/brands/' + this.brandId,
                        {'name': this.name,
                        'criterion_id': this.criterionsSelected.id,
                        'reward_points': this.reward_points,
                        'unit_measure': this.unit_measure})
                        .then(response => {
                            let res = response;
                            this.activateSnackbar(`You have successfully edited the name ${res.data.brand.name}`, 'success')
                            this.cancel(false);
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`error`, 'error')
                        });
                }

            },
            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },

            cancel (v) {
                if(this.brandId === undefined) {
                    this.$router.push(`/brands`);
                }else{
                    this.$emit('closeModal', v)
                }
            }
        },
    }
</script>

<style scoped>

</style>
