<template>
    <v-app>
        <v-container fluid class="mx-0 px-0 lighten-5">
            <v-row>
                <v-col cols="7" class="pb-0">
                    <v-card class="pb-0 py-2 px-2 border-card " :elevation="6" height="355">
                        <div class='h5 pa-2 mb-0'>User Information</div>
                        <v-row no-gutters>
                            <v-col cols="6" class="py-0">
                                <v-card outlined class="mx-1" style='height:100%' >
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Company Name:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.company.name}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">First Name:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.first_name}}</div>
                                        <div class="h6" style="margin-left: 10px">Last Name:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.last_name}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Email:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.email}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Status:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.company.status}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Job Title:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.jobtitle? account.jobtitle.name : ''}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Mobile Phone:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.mobile_phone}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Office Phone:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.office_phone}}</div>
                                    </div>
                                    <div class='d-flex px-3 pt-1'>
                                        <div class="h6">Registration Date:</div>
                                        <div class="h6 px-1" style="font-weight:400">
                                            <date-component :registration="account.created_at"></date-component>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6" class="pr-1">
                                <v-card outlined class='mb-1'>
                                    <div class="h5 px-3 pt-3 pb-0">Bussiness Address</div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">Addres 1:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.billing_address.address1}}</div>
                                    </div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">Address 2:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.billing_address.address2}} </div>
                                    </div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">City:</div>
                                        <div class="h6 px-1" style="font-weight:400;">{{account.billing_address.city.name}} </div>
                                        <div class="h6" style="margin-left: 10px">State:</div>
                                        <div class="h6 px-1" style="font-weight:400;">{{account.billing_address.state.name}} </div>
                                    </div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">Country:</div>
                                        <div class="h6 px-1" style="font-weight:400;">{{account.billing_address.country.name}} </div>
                                    </div>
                                </v-card>
                                <v-card outlined>
                                    <div class="h5 px-3 pt-3 pb-0">Shipping Address</div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">Addres 1:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.shipping_address.address1}}</div>
                                    </div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">Address 2:</div>
                                        <div class="h6 px-1" style="font-weight:400">{{account.shipping_address.address2}} </div>
                                    </div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">City:</div>
                                        <div class="h6 px-1" style="font-weight:400;">{{account.shipping_address.city.name}} </div>
                                        <div class="h6" style="margin-left: 10px">State:</div>
                                        <div class="h6 px-1" style="font-weight:400;">{{account.shipping_address.state.name}} </div>
                                    </div>
                                    <div class='d-flex px-3'>
                                        <div class="h6">Country:</div>
                                        <div class="h6 px-1" style="font-weight:400;">{{account.shipping_address.country.name}} </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="2" height="314">
                    <v-card :elevation="6" class="pa-2 border-card" style="height: 50%" >
                        <div class='h5 pa-2'>Available Points</div>
                        <v-list-item three-line>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline mb-1 pt-6">
                                    <a @click='pointDistribution'>{{pointsAvailable}}</a>
                                </v-list-item-title>
                                <v-list-item-subtitle>Total Points Available</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar tile size="50">
                                <v-icon>mdi-tag-plus</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                    <v-card :elevation="6" class=" mt-2 pa-2 border-card"  style="height: 50%" >
                        <div class='h5 pa-2'>Redeem Points</div>
                        <v-list-item three-line>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline mb-1 pt-6">{{pointsRedeemed}}</v-list-item-title>
                                <v-list-item-subtitle>Total Points Redeemed</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar tile size="50">
                                <v-icon>mdi-tag-minus</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card :elevation="6" class="pa-2 border-card" style="height: 50%"  >
                        <div class='h5 pa-2'>Current Plan</div>
                        <v-list-item three-line>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="h6 mb-1 pt-6">{{suscription}}</v-list-item-title>
                                <v-list-item-subtitle>Due Date: {{dueDate}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                    <v-card :elevation="6" class=" mt-2 pa-2 border-card" style="height: 50%" >
                        <div class='h5 pa-2'>Number of Transactions</div>
                        <v-list-item three-line>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline mb-1 pt-6">{{transactionCount}}</v-list-item-title>
                                <v-list-item-subtitle>Total Number of Transactions</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar tile size="50">
                                <v-icon>mdi-book</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid class="mx-0 px-0 lighten-5">
            <div class='h5 pa-2'>Transactions</div>
            <v-data-table :headers="headers"
                          :items="transactions"
                          :sort-by="['created_at']"
                          :sort-desc="[true]"
                          item-key="id"
                          class="elevation-1"
                          :loading="loading"
                          loading-text="Loading... Please wait">
                <template v-slot:item.created_at="{item, header, value}">
                    <date-component :registration="value"></date-component>
                </template>
            </v-data-table>
        </v-container>
        <v-dialog
            v-model="points_distribution_flag"
            width="800"
        >
            <v-card>
                <v-card-title>
                   <span class="headline font-weight-bold text-uppercase grey--text"> Point Distribution</span>
                </v-card-title>

                <v-card-text>
                    <div class="d-flex">
                        <div class='d-flex justify-space-around' style="width:60%">
                            <div class='flex-column'>
                                <div class='d-flex justify-center'>
                                    <span><b>Shopify Grof USA</b></span>
                                </div>
                                <div class='d-flex justify-center'>
                                    <span>{{grof_usa_shopify}}</span>
                                </div>  
                            </div>
                            <div class='flex-column'>
                                <div class='d-flex justify-center'>
                                    <span><b>Shopify Vives USA</b></span>
                                </div>   
                                <div class='d-flex justify-center'>
                                    <span>{{vives_usa_shopify}}</span>
                                </div>  
                            </div>
                        </div>
                        <v-divider
                            vertical
                        ></v-divider>
                        <div class='d-flex justify-space-around' style="width:100%">
                            <div class='flex-column'>
                                <div class='d-flex justify-center'>
                                    <span><b>Cin7 Grof USA</b></span>
                                </div>   
                                <div class='d-flex justify-center'>
                                    <span>{{grof_usa_points}}</span>
                                </div>  
                            </div>
                            <div class='flex-column'>
                                <div class='d-flex justify-center'> 
                                    <span><b>Cin7 Grof MD</b></span>
                                </div>   
                                <div class='d-flex justify-center'>
                                    <span>{{grof_md_points}}</span>
                                </div> 
                            </div>
                            <div class='flex-column'>
                                <div class='d-flex justify-center'>
                                    <span><b>Cin7 Vives USA</b></span>
                                </div>   
                                <div class='d-flex justify-center'>
                                    <span>{{vives_usa_points}}</span>
                                </div> 
                            </div>
                            <div class='flex-column'>
                                <div class='d-flex justify-center'>
                                    <span><b>Cin7 Vives MD</b></span>
                                </div>   
                                <div class='d-flex justify-center'>
                                    <span>{{vives_md_points}}</span>
                                </div> 
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closePointDistribution">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
    
</template>

<script>
    import DateComponent from "../../components/DateComponent";

    export default {
        name: "Users",
        components: {DateComponent},

        data: () => ({
            account:'',
            account_id: -1,
            transactions: [],
            loading: true,
            pointsAvailable: 0,
            pointsDistributionInformation: [],
            grof_md_points: 0,
            grof_usa_points: 0,
            vives_usa_points: 0,
            vives_md_points: 0,
            grof_usa_shopify: 0,
            vives_usa_shopify: 0,
            pointsRedeemed: 0,
            suscription: '',
            transactionCount: 0,
            dueDate: '',
            points_distribution_flag: false,
            headers: [
                { text: '', value: 'data-table-expand' },
                {text: 'Role', value: 'role'},
                {text: 'Benefit', value: 'benefit_plan.benefit.name'},
                {text: 'Benefit Type', value: 'benefit_plan.benefit.benefit_type.name'},
                {text: 'Assignment Mode', value: 'benefit_plan.benefit.assignment_mode'},
                {text: 'Points', value: 'points'},
                {text: 'Status', value: 'status'},
                {text: 'Date', value: 'created_at'}
            ],
        }),

        mounted(){
            this.account_id = this.$route.params.id;
            this.getAccountInformation(this.account_id)
        },

        methods:{
            getAccountInformation(id){
                axios.get('/api/contacts/'+id).then(res => {
                    this.account = res.data.contact;
                    this.transactions = res.data.contact.transactions;
                    this.pointsAvailable = res.data.contact.points_generated;
                    this.pointsRedeemed = res.data.contact.points_redeemed;
                    this.pointDistributionInformation = res.data.contact.point_distribution;
                    this.grof_md_points= this.pointDistributionInformation['GROF MD LLC-Cin7']
                    this.grof_usa_points= this.pointDistributionInformation['GROF USA Distributors INC.-Cin7']
                    this.vives_usa_points= this.pointDistributionInformation['VBG USA-Cin7']
                    this.vives_md_points= this.pointDistributionInformation['VBG MD LLC-Cin7']
                    this.grof_usa_shopify= this.pointDistributionInformation['GROF USA-Shopify']
                    this.vives_usa_shopify= this.pointDistributionInformation['VBG USA-Shopify']
                    this.transactionCount = res.data.contact.transactions.length;
                    if (res.data.contact.company.plans[0].monthly_price>0){
                        this.dueDate = this.formatDate(res.data.contact.company.plans[0].pivot.expiration_date)
                    }else{
                        this.dueDate ='n/a'
                    }
                    this.suscription = res.data.contact.company.plans[0].name.concat(' Suscription')
                    this.loading = false
                });
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },

            pointDistribution(){
                this.points_distribution_flag = true;
            },

            closePointDistribution(){
                this.points_distribution_flag = false;
            }
        }
    }
</script>

<style scoped>
    .border-card{
        border-bottom: 4px solid #eabd75;
    }
</style>
