<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-btn class='GrofUSA mb-3' color="#eabd75" dark fab hover small @click="openDialog">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                            v-model="search"
                            label="Search"
                            single-line
                            hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers"
                      show-expand
                      single-expand
                      :items="transactions"
                      item-key="id"
                      :search="search"
                      :sort-by="['created_at']"
                      :sort-desc="[true]"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait">
            <template v-slot:item.created_at="{item, header, value}">
                <date-component :registration="value"></date-component>
            </template>
            <template v-slot:item.status="{ item }">
                <v-tooltip v-if="item.denial_observation" bottom>
                    <template v-slot:activator="{ on }">
                        <v-chip class="chip-overflow" width="10" v-on="on" :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                    </template>
                    <span>{{item.denial_observation}}</span>
                </v-tooltip>
                <v-chip v-else class="chip-overflow" :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
            </template>
            <template v-slot:item.data-table-expand="{item, header, expand, isExpanded}">
                <span v-if="item.sale_id" >
                    <v-icon v-if="!isExpanded" @click="expand(!isExpanded)">
                        mdi-chevron-down
                    </v-icon>
                    <v-icon v-else @click="expand(!isExpanded)">
                        mdi-chevron-up
                    </v-icon>
                </span>
            </template>

            <template v-slot:expanded-item="{ item, header, value }">
                <td  colspan="9" style="padding: 40px">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th colspan="9" class="text-center">Sale</th>
                                </tr>
                                <tr>
                                    <th class="text-center">Number</th>
                                    <th class="text-center">Total</th>
                                    <th class="text-center">Used points</th>
                                    <th class="text-center">Earned points</th>
                                    <th class="text-center">Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="text-center">
                                    <td>{{ item.sale.number }}</td>
                                    <td>{{ item.sale.total }}</td>
                                    <td>{{ item.sale.total_used_points }}</td>
                                    <td>{{ item.sale.total_earned_points }}</td>
                                    <td>
                                        <date-component :registration="item.sale.date"></date-component>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                </td>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card ref="form" >
                <v-card-title>
                            <span class="headline font-weight-bold text-uppercase grey--text">Create Manual Transaction</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <span class="title font-weight-bold text-uppercase grey--text">Basic Information</span>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-select
                                    v-model="roleValue"
                                    :items="roles"
                                    label="Roles"
                                    @change="changeUser"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-combobox
                                    ref="user_id"
                                    v-model="userSelected"
                                    :items="users"
                                    :hide-no-data="!search2"
                                    :filter="filter"
                                    :search-input.sync="search2"
                                    hide-selected
                                    :rules="[required]"
                                    label="Select an User"
                                     @change="selectUser"
                                    clearable
                                    >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <span class="subheading">There's no match for: </span>
                                                <span>
                                                    {{ search2 }}
                                                </span>
                                            </v-list-item>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="pr-2" >
                                                {{ formatUserText(item.text) }}
                                            </span>
                                        </template>
                                </v-combobox>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if='userSelected && roleValue && possibleBenefits.length'>
                                <v-select
                                    v-model="benefitSelected"
                                    :items="possibleBenefits"
                                    item-value='id'
                                    item-text='name'
                                    @change="selectBenefit"
                                    label="Manual Benefits"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if='userSelected && roleValue && !possibleBenefits.length'>
                                <div style="display:flex; text-align: center;">
                                    <span style="width:100%;color:black"> There are no manual assignment benefits available to this user </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#eabd75 darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn color="#eabd75" :disabled="saveButton" @click="save">
                        Save
                        <v-icon> mdi-content-save-settings</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="loadingDialog"
            persistent
            max-width="450px"
        >
            <v-card>
                <v-card-text>
                    <v-img
                    src = 'https://cdn.shopify.com/s/files/1/0306/7215/8765/t/12/assets/loadding.svg?v=8868230531791137616'
                    height="350px"
                    contain
                    ></v-img>
                    <v-col cols="12" class="d-flex justify-center">
                        <span class="h4">Please stand by</span>
                    </v-col>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import DateComponent from "../../components/DateComponent";

    export default {
        name: "Dashboard",
        components: {DateComponent},

        data() {
            return {
                search: '',
                headers: [
                    { text: '', value: 'data-table-expand' },
                    {
                        text: 'Customer',
                        align: 'start',
                        value: 'user.name',
                    },
                    {text: 'Role', value: 'role.description'},
                    {text: 'Benefit', value: 'benefit_plan.benefit.name'},
                    {text: 'Benefit Type', value: 'benefit_plan.benefit.benefit_type.name'},
                    {text: 'Points', value: 'points'},
                    {text: 'Status', value: 'status'},
                    {text: 'Date', value: 'created_at'}
                ],
                roles: [],
                benefitSelected: null,
                manualBenefits:[],
                possibleBenefits:[],
                allusers: null,
                users:[],
                plan_id: null,
                userSelected: null,
                search2: null,
                dialog: false,
                saveButton: true,
                roleValue: null,
                transactions: [],
                loading: true,
                loadingDialog:false,
            }
        },

        computed: {
        },

        mounted() {
            this.getTransactions();

            axios.get('/api/roles').then(res => {
                var roles = res.data
                for (var i = 0; i < roles.length; i++) {
                    this.roles.push({ text: roles[i].description, value: roles[i].id });
                }
            })
            axios.get('/api/allusers').then(res => {
                this.allusers= res.data
            })
             axios.get('/api/allmanualbenefits').then(res => {
                this.manualBenefits= res.data.benefits
            })
        },
        methods:{

            getTransactions(){
                 axios.get('/api/transactions').then(res => {
                this.loading = false;
                for (var i = 0; i < res.data.transactions.length; i++) {
                    this.transactions.push(res.data.transactions[i]);
                }
            
            });
            },

            getColor: function (status) {
                if (status === 'Denied') return 'red';
                else if (status === 'Approved') return 'green'
                else return ''
            },

            openDialog: function(){
                this.dialog = true
            },

            criterion(role_id, plan_id){
                return function(benefit) {
                    return benefit.role_id == role_id && benefit.plan_id == plan_id;
                }
            },

            getPossibleBenefits(){
                var possibleBenefits = this.manualBenefits.filter(this.criterion(this.roleValue,this.plan_id));
                console.log(possibleBenefits)
                this.possibleBenefits = possibleBenefits
            },

            selectBenefit(){
                if (this.benefitSelected){
                    this.saveButton = false
                }
            },

            selectUser(){
                this.plan_id = this.userSelected.plan_id;
                this.benefitSelected = null
                this.saveButton=true
                if (this.userSelected){
                    this.getPossibleBenefits();
                }
            },

            changeUser(id){
                this.users = []
                this.userSelected = null
                this.benefitSelected = null
                this.saveButton=true
                this.users.push({ header: 'Select an Option' },)
                if (id==3){
                    for (var i = 0; i < this.allusers.professional_users.length; i++) {
                        this.users.push(this.allusers.professional_users[i]);
                    }
                }else{
                    for (var i = 0; i < this.allusers.personal_users.length; i++) {
                        this.users.push(this.allusers.personal_users[i]);
                    }
                }
            },

            filter (item, queryText, itemText) {
                if (item.header) return false

                const hasValue = val => val != null ? val : ''

                const text = hasValue(itemText)
                const query = hasValue(queryText)

                return text.toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1
            },

            formatUserText(userText){
                if(userText.includes('(')){
                    var name = userText.split("(")[0]
                    var email = userText.split("-")[1]
                    return name.concat('-',email)
                }else{
                    return userText
                }
            },

            required(value) {
                if (value instanceof Array && value.length == 0) {
                    return 'Required.';
                }
                return !!value || 'Required.';
            },

            save(){
                axios.post('/api/manualtransaction',{
                    'benefit_plan_id': this.benefitSelected,
                    'user_id': this.userSelected.value,
                    'role_id':this.roleValue
                }).then(response => {
                    let res = response;
                    this.loading=true
                    this.transactions=[]
                    this.getTransactions()
                    this.loadingDialog = false
                })
                .catch(error=> {
                    this.closeDialog()
                })
                this.closeDialog()
                this.loadingDialog = true
            },

            closeDialog: function(){
                this.dialog = false
            }

        },


    };
</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    .container{
        max-width: 100vw;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }
    .chip-overflow {
        max-width: 90px;
        display:block;
    }
</style>
