<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-btn class='GrofUSA mb-3' color="#eabd75" dark fab hover small @click='newItem'>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
        </v-container>
        
        <v-data-table :headers="headers"
                      :items="price_categories"
                      item-key="id"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait">

            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon small @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </td>
            </template>
        </v-data-table>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card ref="form" >
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title> 
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field ref="name"
                                        v-model="editedItem.name"
                                        label="Name"
                                        :rules="[() => !!editedItem.name|| 'Provide a Name']"
                                        required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field ref="points_multiplier"
                                        v-model="editedItem.points_multiplier"
                                        label="Points Multiplier"
                                        :rules="[() => !!editedItem.points_multiplier|| 'Provide a value for points multiplier']"
                                        required>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#eabd75 darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="#eabd75 darken-1" text @click="save">Save</v-btn>
            </v-card-actions>

         </v-card>

    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
            {{ snackbartext }}
            <v-btn
                dark
                text
                @click="snackbar = false"
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>

    </v-snackbar>
    </v-app>
</template>


<script>
    export default {
        name: "Dashboard",

        data() {
            return {
                search: '',
                editedIndex: -1,
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    {text: 'Multiplier', value: 'points_multiplier'},
                    {text: 'Actions', value: 'actions', sortable: false},
                    ],
                editedItem: {
                    name: '',
                    points_multiplier: ''
                },
                defaultItem: {
                    name: '',
                    points_multiplier: ''
                },
                price_categories: [],
                loading: true,
                dialog:false,
            }
        },

         computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Price Category" : "Edit Price Category";
            },
        },

        mounted() {
            this.getItems();
        },

        methods:{
            getItems(){
                axios.get('/api/price_categories').then(res => {
                this.loading = false;
                this.price_categories = res.data.price_categories;
                });
            },

            newItem(){
                this.dialog=true;
            },
            
            form() {
                let form={
                    "name":this.editedItem.name,
                    "points_multiplier": this.editedItem.points_multiplier,
                }

                return form
            },

            resetForm () {
                this.formHasErrors = false;

                Object.keys(this.form()).forEach(f => {
                    this.$refs[f].reset()
                })
            },

            close(){
                this.dialog = false;
                this.resetForm();
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            
            editItem(item){
                this.editedIndex = this.price_categories.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },

            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },

            deleteItem(item){
            const index = this.price_categories.indexOf(item);
                if(confirm("Are you sure You want to delete this Price Category?") && this.price_categories.splice(index, 1)){
                    axios.delete('/api/price_categories/' + item.id
                    ).then(res => {
                        let response = res.data;
                        this.activateSnackbar(`You have successfully deleted the Price Category`, 'success');
                    }).catch(error=> {
                        this.activateSnackbar(`Error`, 'error')
                    });
                }

            },

            save(){
                this.formHasErrors = false;

                Object.keys(this.form()).forEach(f => {
                    if (!this.form()[f]) {
                        this.formHasErrors = true;
                        console.log(f)
                        console.log(this.form()[f])
                    }

                    this.$refs[f].validate(true);
                });

                if (!this.formHasErrors){
                    if (this.editedIndex > -1) {
                        axios.put('/api/price_categories/' + this.price_categories[this.editedIndex].id,
                            this.form(),
                        ).then(res => {
                            this.getItems();
                            this.activateSnackbar(`You have successfully created a Price Category`, 'success');
                        }).catch(error=> {
                            this.activateSnackbar(`Error`, 'error')
                        })
                    }else{
                         axios.post('/api/price_categories',
                            this.form(),
                        ).then(res => {
                            let response = res.data.price_category;
                            this.price_categories.push(response);
                            this.activateSnackbar(`You have successfully created a Price Category`, 'success');
                        })
                        .catch(error=> {
                            this.activateSnackbar(`Error`, 'error')
                        })
                    }
                    this.close();
                }   
            }
        }
        }

</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }
</style>