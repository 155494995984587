var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{staticClass:"mr-0 ml-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"GrofUSA mb-3",attrs:{"color":"#eabd75","dark":"","fab":"","hover":"","small":"","to":"/managebenefits"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"show-expand":"","items":_vm.benefits,"item-key":"name","search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.plans",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"40px"},attrs:{"colspan":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344","outlined":""}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Plans")])])]),_vm._v(" "),_c('tbody',_vm._l((item.plans),function(benefit_plan){return _c('tr',[_c('td',[_vm._v(_vm._s(benefit_plan.plan.name))])])}),0)]},proxy:true}],null,true)})],1)],1)]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('manage-benefits',{staticClass:"p-4",attrs:{"benefit-id":_vm.benefitId},on:{"closeModal":function($event){return _vm.closeModal($event)}}})],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Are you sure you want to delete this benefit?")])])],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItem}},[_vm._v("Accept")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"non-clickable",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("\n                    mdi-pencil\n                ")]),_vm._v(" "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openNotification(item)}}},[_vm._v("\n                    mdi-delete\n                ")])],1)]}}])}),_vm._v(" "),_c('v-snackbar',{attrs:{"timeout":2000,"color":_vm.snackbarcolor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbartext)+"\n        "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }