<template>
    <v-app>
        <v-card-title>
            <span class="headline font-weight-bold text-uppercase grey--text">{{title}}</span>
        </v-card-title>
        <v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step color="#eabd75" :complete="e1 > 1" step="1">Basic Info</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="#eabd75" :complete="e1 > 2" step="2">Assign Benefits</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="#eabd75" step="3">Configure Benefits</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items class="stepper-scrollable-content">
                <v-stepper-content step="1">
                        <v-card color="#FCFCFC" class="mb-12" height="300px" >
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="plan.name" label="Name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-select
                                            v-model="plan.plan_type"
                                            :items="planList"
                                            label="Tipo de Plan"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field v-model="plan.monthly_price" label="Monthly Price" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field v-model="plan.six_months_price" label="Six Months Price" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="plan.expired_time_points" label="Use Points Pefore(months)" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="plan.total_decision_maker" label="Decisions Makers Number" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="plan.point_value" label="% Over Sale/Purchase" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>

                        <v-btn text @click="cancel()">Cancel</v-btn>

                        <v-btn color="#eabd75" @click="savePlan(2)">
                            Continue
                            <v-icon> mdi-page-next-outline </v-icon>
                        </v-btn>
                    </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card color="#FCFCFC" class="mb-12" height="300px" style="overflow: auto">
                            <v-container>
                                <v-data-table
                                        :headers="headers"
                                        :items="benefitsPlan"
                                        item-key="name"
                                        hide-default-footer
                                        :items-per-page="benefitsPlan.length"
                                        class="elevation-1">
                                    <template v-slot:item.personal="{ item }">
                                        <v-simple-checkbox v-model="item.personal"></v-simple-checkbox>
                                    </template>
                                    <template v-slot:item.professional="{ item }">
                                        <v-simple-checkbox v-model="item.professional"></v-simple-checkbox>
                                    </template>
                                    <template v-slot:item.Personal="{ item }">
                                        <v-simple-checkbox v-model="item.Personal"></v-simple-checkbox>
                                    </template>
                                    <template v-slot:item.Professional="{ item }">
                                        <v-simple-checkbox v-model="item.Professional"></v-simple-checkbox>
                                    </template>
                                </v-data-table>
                                <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
                                    {{ snackbartext }}
                                    <v-btn dark text @click="snackbar = false">
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-snackbar>
                            </v-container>
                    </v-card>

                    <v-btn text @click="cancel()">Cancel</v-btn>
                    <v-btn color="#eabd75" @click="saveAssign(3)">
                        Continue
                        <v-icon> mdi-page-next-outline </v-icon>
                    </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card color="#FCFCFC" class="mb-12" height="300px" style="overflow: auto">
                        <v-container>
                            <v-data-table
                                    :headers="headersConf"
                                    :items="plan.benefit_plan"
                                    :items-per-page="plan.benefit_plan.length"
                                    item-key="id"
                                    hide-default-footer
                                    class="elevation-1">
                                <template v-slot:item.benefit="{ item, value}">
                                    <span>{{ item.benefit.name }} - {{item.role.description}}</span>
                                </template>
                                <template v-slot:item.value="{ item }">
                                    <div class="text-field-g">
                                        <v-edit-dialog dark
                                                       :return-value="item.value"
                                        > {{ item.value }}
                                            <template v-slot:input>
                                                <v-text-field
                                                        v-model="item.value"
                                                        label="Value"
                                                        single-line
                                                        type="number"
                                                ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </div>
                                </template>
                                <template v-slot:item.path="{ item }">
                                    <v-select
                                            v-model="cSel"
                                            :items="item.path"
                                    ></v-select>
                                </template>
                                <template v-slot:item.criterion_id="{ item }">
                                    <v-select class="select-criterion"
                                            v-model="item.criterion_id"
                                            :items="criterions"
                                            item-text="name"
                                            item-value="id"
                                            single-line
                                    ></v-select>
                                </template>
                                <template v-slot:item.renewable="{ item }">
                                    <v-simple-checkbox v-model="item.renewable"></v-simple-checkbox>
                                </template>
                                <template v-slot:item.renewal_value="{ item }">
                                    <div class="text-field-g">
                                    <v-edit-dialog dark
                                            :return-value="item.renewal_value">
                                        {{ item.renewal_value }}
                                        <template v-slot:input>
                                            <v-text-field
                                                    v-model="item.renewal_value"
                                                    label="Renewal value"
                                                    single-line
                                                    type="number"
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                    </div>
                                </template>

                            </v-data-table>
                        </v-container>
                    </v-card>

                    <v-btn text @click="cancel()">Cancel</v-btn>
                    <v-btn color="#eabd75" @click="saveConfig(1)">
                        Continue
                        <v-icon> mdi-page-next-outline </v-icon>
                    </v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-app>
</template>

<script>
    export default {
        name: "ManagePlans",
        props:['id'],
        data() {
            return {
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
                snack: false,
                snackColor: '',
                snackText: '',
                planId: -1,
                plan: {
                    name:'',
                    monthly_price:'',
                    six_months_price:'',
                    expired_time_points:'',
                    total_decision_maker:'',
                    point_value:'',
                    benefit_plan: []

                },
                title: 'New plan',
                e1: 1,
                benefitsPlan: [],
                roles: [],
                headers: [
                    {
                        text: 'Benefits - Roles',
                        align: 'start',
                        sortable: false,
                        value: 'name'
                    },
                ],
                headersConf: [{text: 'Benefits - Roles', align: 'start', value: 'benefit'},
                    { text: 'Value', value: 'value' },
                    { text: 'Criterion', value: 'criterion_id' },
                    { text: 'Es Renewable', value: 'renewable'},
                    { text: 'Renewal Value', value: 'renewal_value'}
                ],
                criterions:[],
                planList: [ {text:'Regular Plan', value:'Regular'},
                    {text:'Trial Plan', value:'Trial'},
                    {text:'Design Plan', value:'Design'}
                ],
            }
        },
        mounted() {
            var criterion_type = 'benefit';
            axios.get('/api/criterions/' + criterion_type).then(res => {
                this.criterions=res.data.criterions;
            });
            if(this.$route.params.id){
                this.planId = this.$route.params.id;
                this.title = 'Edit plan'
                axios.get('/api/plans/'+this.planId).then(res => {
                    this.plan = res.data.plan;
                    axios.get('/api/roles').then(res => {
                        this.roles = res.data;
                        for (var i = 0; i < this.roles.length; i++) {
                            this.headers.push({ text: this.roles[i].description, value: this.roles[i].name });
                        }
                        axios.get('/api/benefits').then(res => {
                            this.benefitsPlan = res.data.benefits;
                            for (var i = 0; i < this.benefitsPlan.length; i++) {
                                for (var j = 0; j < this.roles.length; j++) {
                                    const result = this.plan.benefit_plan.filter(
                                        benefitPlan => (benefitPlan.benefit_id == this.benefitsPlan[i].id && benefitPlan.role_id == this.roles[j].id )
                                    );

                                    if(result.length > 0){
                                        this.$set(this.benefitsPlan[i], this.roles[j].name , true);
                                    }else{
                                        this.$set(this.benefitsPlan[i], this.roles[j].name , false)
                                    }
                                }
                            }
                        });
                    });
                });

            }else{
                axios.get('/api/roles').then(res => {
                    this.roles = res.data;
                    for (var i = 0; i < this.roles.length; i++) {
                        this.headers.push({ text: this.roles[i].description, value: this.roles[i].name });
                    }
                    axios.get('/api/benefits').then(res => {
                        this.benefitsPlan = res.data.benefits;
                        for (var i = 0; i < this.benefitsPlan.length; i++) {
                            for (var j = 0; j < this.roles.length; j++) {
                                this.$set(this.benefitsPlan[i], this.roles[j].name , false)
                            }
                        }
                    });
                });
            }
        },
        methods:{
            savePlan (e) {
                this.e1 = e;
                if(this.planId == -1) {
                    axios.post('/api/plans', this.plan)
                        .then(response => {
                            let res = response;
                            this.planId = res.data.plan.id;
                            this.plan = res.data.plan;
                            this.plan.benefit_plan = [];
                            this.activateSnackbar(`You have successfully created the name ${res.data.plan.name}`, 'success')
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`Error`, 'error')
                        });
                } else {
                    axios.put('/api/plans/basicInfo/' + this.planId, this.plan)
                        .then(response => {
                            let res = response;
                            this.plan = res.data.plan;
                            this.activateSnackbar(`You have successfully edit the name ${res.data.plan.name}`, 'success')
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`Error`, 'error')
                        });
                }
            },
            saveAssign (e) {
                this.e1 = e;

                Object.assign(this.plan.benefit_plan, this.benefitsPlan);
                axios.put('/api/plans/' + this.planId, this.plan)
                    .then(response => {
                        let res = response;
                        this.plan = res.data.plan;
                        this.activateSnackbar(`You have successfully Assigned Benefits`, 'success')
                    })
                    .catch(error=> {
                        console.log(error);
                        this.activateSnackbar(`Error`, 'error')
                    });
            },
            saveConfig (e) {
                axios.put('/api/plans/configureBenefits/' + this.planId, this.plan)
                    .then(response => {
                        let res = response;
                        this.plan = res.data.plan;
                        this.activateSnackbar(`You have successfully Configure Benefits`, 'success')
                        this.$router.push(`/plans`)
                    })
                    .catch(error=> {
                        console.log(error);
                        this.activateSnackbar(`Error`, 'error')
                    });

            },
            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },

            cancel(){
                this.$router.push(`/plans`);
            },

        },
    }
</script>

<style scoped>
    .text-field-g {
        border-radius: 3px;
        border-style: solid;
        border-width: 2px;
        border-color: #757575;
        width: 65px;
    }
    .select-criterion {
        width: 100px;
    }

    .stepper-scrollable-content {
        overflow: auto;
        max-height: calc(100% - 71px);
    }

</style>
