<template>
    <v-container fluid>
        <v-row justify="space-around">
            <v-col cols="12" xs='6' md='6' sm='6' lg='6' xl='6'>
                <v-sheet class="pa-3" color="grey lighten-5">
                    <v-card :elevation="6" height="400" class="mx-auto border-card">
                        <v-card-title class="py-0">Users</v-card-title>
                        <v-card-text class="py-0">
                            <v-row no-gutters>
                                <v-col cols="6" class="pr-1">
                                    <v-card class="pt-3" max-width="344" height="310" outlined>
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div class="overline mb-4">Professional Account</div>
                                                <v-list-item-title class="headline mb-1 pt-6">{{parseFloat(count_companies).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                                <v-list-item-subtitle>Total of active Professional users</v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-avatar
                                                    tile
                                                    size="50">
                                                <v-icon>mdi-office-building</v-icon>
                                            </v-list-item-avatar>
                                        </v-list-item>
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="headline mb-1">{{parseFloat(points_unredeemed_pro).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                                <v-list-item-subtitle>Unredeemed points</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </v-col>
                                <v-col cols="6">
                                    <v-card class="pt-3" max-width="344" height="310" outlined>
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div class="overline mb-4">Personal Account</div>
                                                <v-list-item-title class="headline mb-1 pt-6">{{parseFloat(count_clients).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                                <v-list-item-subtitle>Total of active Personal Account users</v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-avatar
                                                    tile
                                                    size="50">
                                                <v-icon>mdi-account-badge-horizontal-outline</v-icon>
                                            </v-list-item-avatar>
                                        </v-list-item>
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="headline mb-1">{{parseFloat(points_unredeemed_per).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                                <v-list-item-subtitle>Unredeemed points</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="#eabd75" block :to="`/users`">
                                <v-icon> mdi-magnify </v-icon>
                                Details
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-sheet>
            </v-col>
            <v-col cols="12" xs='6' md='6' sm='6' lg='6' xl='6'>
                <v-sheet class="pa-3" color="grey lighten-5">
                    <v-card :elevation="6" class="mx-auto border-card">

                    <v-row >
                        <v-col cols="9" class="pt-0 pb-0">
                            <v-card-title class="py-0">Transactions</v-card-title>
                        </v-col>
                        <v-col cols="3" class="pt-1 pb-0">
                            <div >Since {{first_date_sale}}</div>
                        </v-col>
                    </v-row>
                    <v-card-text class="py-0">
                        <v-row no-gutters>
                            <v-col cols="6" class="pr-1">
                                <v-card outlined>
                                    <v-list-item three-line>
                                        <v-list-item-content class="py-0">
                                            <div class="overline pt-1">Income Points</div>
                                            <div class="overline mb-0">Generated</div>
                                            <v-list-item-title class="headline mb-1">{{parseFloat(points_generatedIncome).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                            <v-list-item-subtitle>Total Points Generated</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-avatar tile size="50">
                                            <v-icon>mdi-tag-plus</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="headline mb-1">${{parseFloat(points_generated_approximateIncome).toLocaleString('de-DE', {minimumFractionDigits: 2})}}</v-list-item-title>
                                            <v-list-item-subtitle>Approximate($)</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-card outlined>
                                    <v-list-item three-line>
                                        <v-list-item-content class="py-0">
                                            <div class="overline pt-1">Points by Interaction Generated</div>
                                            <v-list-item-title class="headline mb-1">{{parseFloat(points_generatedInteraction).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                            <v-list-item-subtitle>Total Points Generated</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-avatar tile  size="50">
                                            <v-icon>mdi-tag-plus</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="headline mb-1">${{parseFloat(points_generated_approximateInteraction).toLocaleString('de-DE', {minimumFractionDigits: 2})}}</v-list-item-title>
                                            <v-list-item-subtitle>Approximate($)</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="pt-1">
                            <v-col cols="6" class="pr-1">
                                <v-card outlined>
                                    <v-list-item three-line>
                                        <v-list-item-content class="py-0">
                                            <div class="overline pt-1">Points Redeemed</div>
                                            <v-list-item-title class="headline mb-1">{{parseFloat(points_redeemed).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                            <v-list-item-subtitle>Total Points Redeemed</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-avatar
                                                tile
                                                size="50">
                                            <v-icon>mdi-tag-minus</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="headline mb-1">${{parseFloat(points_redeemed_approximate).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                            <v-list-item-subtitle>Approximate($)</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col cols="6" >
                                <v-card outlined>
                                    <v-list-item three-line>
                                        <v-list-item-content class="py-0">
                                            <div class="overline pt-1">Sales Dispatched</div>
                                            <v-list-item-title class="headline mb-1">${{parseFloat(total_sales).toLocaleString('de-DE', {minimumFractionDigits: 0})}}</v-list-item-title>
                                            <v-list-item-subtitle>Total Sales</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-avatar
                                                tile
                                                size="50">
                                            <v-icon>smdi-cash-check</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="headline mb-1">{{parseFloat(rebase).toLocaleString('de-DE', {minimumFractionDigits: 2})}}%</v-list-item-title>
                                            <v-list-item-subtitle>Approximate % Over Sale/Purchase</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="#eabd75" block :to="`/transactions`">
                                <v-icon> mdi-magnify </v-icon>
                                Details
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col cols="12" xs="8" md="8" sm="8" lg="8" xl="8">
                <v-sheet class="pa-1" color="grey lighten-5">
                    <v-card :elevation="6" class="mx-1 border-card" height="430">
                        <v-card-title class="pa-1">Interactions pending to approved</v-card-title>
                            <v-container class="mr-0 ml-0 pa-0">
                                <v-row no-gutters>
                                    <v-spacer></v-spacer>
                                    <v-col cols="12" sm="4">
                                        <v-text-field class="pa-0"
                                                v-model="search"
                                                label="Search"
                                                single-line
                                                hide-details
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-data-table :headers="headers"
                                          :items="interactions"
                                          item-key="name"
                                          :search="search"
                                          class="elevation-1"
                                          :sort-by="['created_at']"
                                          :sort-desc="[true]"
                                          :loading="loading"
                                          :items-per-page="5"
                                          loading-text="Loading... Please wait">
                                <template v-slot:item.created_at="{item, header, value}">
                                    <date-component :registration="value"></date-component>
                                </template>
                                <template v-slot:top>
                                    <v-dialog v-model="dialog" max-width="500px">
                                        <v-card>
                                            <v-card-text>
                                                <v-container class="pt-3">
                                                    <v-col cols="12">
                                                        <span class="subtitle-1">Are you sure you want to do this action?</span>
                                                    </v-col>
                                                    <div class='pricing-tab-wrapper pt-5'>
                                                        <div class='pricing-tab'>
                                                            <span class='toggle-slide'>
                                                                <em class='toggle-btn'>&nbsp;</em>
                                                            </span>
                                                            <span class="label-text approve active" @click="approve">Approve</span>
                                                            <span class="label-text deny" @click="deny">Deny<em></em></span>
                                                        </div>
                                                    </div>
                                                    <v-textarea v-if="!toApprove" solo v-model="obs" label="Denial Observation"></v-textarea>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn class="color-gsrp" text @click="accept">Accept</v-btn>
                                                <v-btn class="color-gsrp" text @click="close">Cancel</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </template>
                                <template v-slot:item.actions="{ item }">

                                    <td @click.stop class="non-clickable">
                                        <v-btn fab dark x-small color="#eabd75"  v-on:click="open(item)">
                                            <v-icon>mdi-marker-check</v-icon>
                                        </v-btn>
                                    </td>
                                </template>
                            </v-data-table>
                        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
                            {{ snackbartext }}
                            <v-btn  dark text @click="snackbar = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-snackbar>
                    </v-card>
                </v-sheet>
            </v-col>
            <v-col cols="12" xs="4" md="4" sm='4' lg='4' xl='4'>
                <v-sheet class="pa-1" color="grey lighten-5">
                    <v-card :elevation="6" class="mx-1 border-card" height="430"  min-width="350">
                        <v-card-title>Store reward points</v-card-title>
                        <v-row>
                            <v-col cols="12">
                                <v-simple-table
                                        height="300">
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left">Name</th>
                                            <th class="text-left">Points Generated</th>
                                            <th class="text-left">Points Redeemed</th>
                                            <th class="text-left">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="item in shops">
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.points_generated == null ? 0 : item.points_generated }}</td>
                                            <td>{{ item.points_redeemed == null ? 0 : item.points_redeemed }}</td>
                                            <td>
                                                <v-btn fab dark x-small color="#eabd75" router :to="`/transactionsByShop/${item.shop_id}`">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import DateComponent from "../../components/DateComponent";

    export default {
        name: "Home",
        components: {DateComponent},

        data: () => {
            return {
                search: '',
                loading: true,
                dialog: false,
                toApprove: true,
                index: -1,
                interactionId: -1,
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
                obs: '',
                first_date_sale :0,
                count_clients: 0,
                count_companies: 0,
                points_generatedIncome: 0,
                points_generated_approximateIncome: 0,
                points_generatedInteraction: 0,
                points_generated_approximateInteraction: 0,
                points_redeemed: 0,
                rebase:0,
                points_redeemed_approximate: 0,
                points_unredeemed_pro: 0,
                points_unredeemed_per: 0,
                total_sales: 0,
                interactions : [],
                shops : [],
                headers: [
                    {
                        text: 'Customer',
                        align: 'start',
                        value: 'user.name',
                    },
                    {text: 'Role', value: 'role'},
                    {text: 'Benefit', value: 'benefit_plan.benefit.name'},
                    {text: 'Points', value: 'points'},
                    {text: 'Status', value: 'status'},
                    {text: 'Date', value: 'created_at'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],

            }
        },
        mounted() {
            axios.get('/api/dataDash').then(res => {
                this.count_clients = res.data['clients_count'] + res.data['contacts_count'];
                this.count_companies = res.data['companies_count'];
                this.points_generatedIncome = res.data['points_generatedIncome'] == null ? 0 : res.data['points_generatedIncome'];
                this.points_generated_approximateIncome = res.data['points_generated_approximateIncome'];
                this.points_generatedInteraction = res.data['points_generatedInteraction'] == null ? 0 : res.data['points_generatedInteraction'];
                this.points_generated_approximateInteraction = res.data['points_generated_approximateInteraction'];
                this.points_redeemed = res.data['points_redeemed'] == null ? 0 : res.data['points_redeemed'];
                this.rebase = (res.data['rebase']*100).toFixed(2);
                this.points_redeemed_approximate =  res.data['points_redeemed_approximate'];
                this.interactions = res.data['transactions_pendding'];
                this.total_sales = res.data['total_sales'];
                this.first_date_sale=res.data['first_date_sale'];
                this.shops = res.data['shops'];
                this.points_unredeemed_pro = res.data['points_unredeemed_pro'];
                this.points_unredeemed_per = res.data['points_unredeemed_per'];
                this.loading = false;
            });
        },
        methods:{
            accept: function () {
                if( this.toApprove ){
                    axios.put('/api/transactions/' + this.interactionId)
                        .then(response => {
                            let res = response.data['transaction'];
                            this.interactions.splice(this.index, 1);
                            this.points_generatedIncome = parseInt(this.points_generatedIncome, 10) + res['points'];
                            this.points_generated_approximateIncome =  parseInt(this.points_generated_approximateIncome, 10) + (res['points'] * res['point_value']);
                            this.activateSnackbar(`You have successfully approved`, 'success')
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`error`, 'error')
                        });
                }else{
                    axios.put('/api/transactions/deny/' + this.interactionId, {'obs': this.obs})
                        .then(response => {
                            let res = response;
                            this.interactions.splice(this.index, 1);
                            this.activateSnackbar(`You have successfully deleted the transaction`, 'success')
                        })
                        .catch(error=> {
                            console.log(error);
                            this.activateSnackbar(`Error`, 'error')
                        });
                }
                this.close();
            },

            close () {
                this.dialog = false;
                this.toApprove = true;
                this.interactionId = -1;
                this.index = -1;
            },
            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },
            open: function (item) {
                this.index = this.interactions.indexOf(item);
                this.dialog = true;
                this.interactionId = item.id;

            },
            deny: function () {
                var toggle_button= document.getElementsByClassName('toggle-btn')[0];
                var approve_text = document.getElementsByClassName('approve')[0];
                var deny_text = document.getElementsByClassName('deny')[0];
                toggle_button.style.marginLeft = "52%";
                approve_text.classList.remove("active");
                deny_text.classList.add("active");
                this.toApprove = false;
            },
            approve: function () {
                var toggle_button= document.getElementsByClassName('toggle-btn')[0];
                var approve_text = document.getElementsByClassName('approve')[0];
                var deny_text = document.getElementsByClassName('deny')[0];
                toggle_button.style.marginLeft = "4px";
                approve_text.classList.add("active");
                deny_text.classList.remove("active");
                this.toApprove = true;
            },
        }
    }
</script>

<style scoped>
    .pricing-tab-wrapper{
        display:flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        margin: 0 auto 20px auto;
    }
    .pricing-tab{
        position: relative;
    }
    .pricing-tab span{
        width: 50%;
        vertical-align: middle;
        position: relative;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        text-align:center;
        transition: 0.5s all ease;
    }
    .pricing-tab .toggle-slide{
        border: solid 1px;
        display: flex;
        background: white;
        border-radius:20px;
        margin: 0 auto;
        height:35px;
        cursor: pointer;
        user-select: none;
        outline: 0;
        width: 156px;
        box-shadow: 2px 4px 9px 0px rgba(119, 119, 119, 0.4);
    }
    .label-text{
        font-size: 14px;
        line-height: 1;
        color: #eabd75;
    }
    .toggle-btn{
        margin-left: 4px;
        left: 0px;
        width: 70px;
        background: #eabd75;
        border-color: #eabd75;
        border-radius: 20px;
        height: 25px;
        margin-top: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s ease-in-out;
    }
    .pricing-tab span.approve,
    .pricing-tab span.deny{
        position: absolute;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .pricing-tab span.approve{
        left: 1px;
    }
    .approve.active, .deny.active{
        color: white;
    }
    .border-card{
        border-bottom: 4px solid #eabd75;
    }
    .color-gsrp{
        color: #eabd75;
    }
</style>
