<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                            v-model="search"
                            append-icon="mdi-search"
                            label="Search"
                            single-line
                            hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers"
                      :items="brands"
                      item-key="id"
                      :search="search"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait" >
            <template v-slot:item.name="{ item }">
                <span>{{ capitalizeFirstLetter(item.name) }}</span>
            </template>
            <template v-slot:item.criterion="{ item}">
                <span>{{ item.criterion }}</span>
            </template>
            <template v-slot:item.unit_measure="{ item}">
                <span>{{ capitalizeFirstLetter(item.unit_measure) }}</span>
            </template>
            <template v-slot:item.reward_points="{ item}">
                <span>{{ item.reward_points }}</span>
            </template>
            <template v-slot:top>
                <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                        <v-card-title>
                            <span class="headline font-weight-bold text-uppercase grey--text">Edit Brand</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <span class="title font-weight-bold text-uppercase grey--text">Basic Information</span>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-select
                                            v-model="editedItem.criterion_id"
                                            :items="criterions"
                                            item-text="name"
                                            item-value="id"
                                            label="Criterion"
                                            return-object
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="editedItem.reward_points" label="Reward points"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-text-field v-model="editedItem.unit_measure" label="Unit measure"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click='close()'>Cancel</v-btn>
                                    <v-btn color="#eabd75" @click="save()">
                                        Save
                                        <v-icon> mdi-content-save-settings</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon small class="mr-2" v-on:click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                </td>
            </template>
        </v-data-table>
        <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarcolor">
            {{ snackbartext }}
            <v-btn
                    dark
                    text
                    @click="snackbar = false"
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>

        </v-snackbar>
    </v-app>
</template>

<script>
    import ManageBrands from "../Brands/ManageBrands";

    export default {
        name: "Dashboard",
        components: {ManageBrands},

        data() {
            return {
                snackbar: false,
                snackbartext: '',
                snackbarcolor: '',
                search: '',
                dialog: false,
                notification: false,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: 'Pricing Group', value:'pricing_group'},
                    {text: 'Criterion', value: 'criterion'},
                    {text: 'Unit Measure', value: 'unit_measure'},
                    {text: 'Reward Points', value:'reward_points' },
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                brands: [],
                brandId: -1,
                index: -1,
                deletedItemId: -1,
                loading: true,
                editedItem:{
                    name:'',
                    reward_points: 0,
                    unit_measure: '',
                    criterion_id: 0
                },
                criterions:[]
            }
        },

        mounted() {
            axios.get('/api/brands').then(res => {
                this.brands = res.data.brands;
                this.loading = false;
            });

            var criterion_type = 'product';
            axios.get('/api/criterions/' + criterion_type).then(res => {
                this.criterions=res.data.criterions;
            });

        },

        methods:{
            editItem: function (item) {
                this.dialog = true
                this.brandId = item.id;
                this.editedItem = item;
            },
            openNotification: function (item) {
                this.notification = true;
                this.index = this.brands.indexOf(item);
                this.deletedItemId = item.id;
            },
            capitalizeFirstLetter: function (string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },

            close () {
                this.dialog = false;
                this.notification=false;
                this.brandId = -1;
                this.index = -1;
                this.deletedItemId = -1;
            },

            save(){
                axios.put('/api/brands/' + this.brandId,
                        {'name': this.editedItem.name,
                        'criterion_id': this.editedItem.criterion_id.id,
                        'reward_points': this.editedItem.reward_points,
                        'unit_measure': this.editedItem.unit_measure})
                    .then(response => {
                        let res = response;
                        var index = this.brands.findIndex(brands=>brands.id==res.data.brand.id);
                        this.brands[index]=res.data.brand
                        this.activateSnackbar(`You have successfully edited the name ${res.data.brand.name}`, 'success')
                        this.close();
                    })
                    .catch(error=> {
                        console.log(error);
                        this.activateSnackbar(`error`, 'error')
                    });
            },
            closeModal (value) {
                axios.get('/api/brands').then(res => {
                    this.brands = res.data.brands;
                });
            },
            activateSnackbar(text){
                this.snackbar= true;
                this.snackbartext = text;
            },
        },
    };
</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }

    .test{
        background-color: rgba(0, 0, 0, 0);
    }
</style>
