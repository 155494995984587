var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{staticClass:"mr-0 ml-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"GrofUSA mb-3",attrs:{"dark":"","color":"#eabd75","fab":"","hover":"","small":"","to":"/manageproducts"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Create New Product")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"GrofUSA mb-3",staticStyle:{"margin":"0 15px"},attrs:{"dark":"","color":"#eabd75","fab":"","hover":"","small":"","to":"/products/import"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-download")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Import Products")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"GrofUSA mb-3",attrs:{"dark":"","color":"#eabd75","fab":"","hover":"","small":"","to":"/products/price"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-playlist-edit")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Price Update")])]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.processSyncActive)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#eabd75"}},[_c('span',[_vm._v("sync")])]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Search Ex: 1900 (Press Enter)","single-line":"","hide-details":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchQuery($event)},"click:clear":_vm.getList},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.products,"item-key":"id","search":_vm.search,"loading":_vm.loading,"items-per-page":25,"hide-default-footer":"","loading-text":"Loading... Please wait"},on:{"click:row":_vm.goToEditProduct},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_c('td',{staticClass:"non-clickable",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editProduct(item)}}},[_vm._v("\n                    mdi-pencil\n                ")]),_vm._v(" "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openNotification(item)}}},[_vm._v("\n                    mdi-delete\n                ")])],1)]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Are you sure you want to delete this product?")])])],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItem}},[_vm._v("Accept")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1)]},proxy:true},{key:"item.brand.name",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [(value == 'not assign')?_c('span',{staticClass:"brandTag"},[_vm._v("\n                "+_vm._s(item.brand_name)+"\n            ")]):_c('span',{staticClass:"brandTag"},[_vm._v("\n                "+_vm._s(item.brand.name)+"\n            ")])]}}])}),_vm._v(" "),_c('v-snackbar',{attrs:{"timeout":2000,"color":_vm.snackbarcolor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbartext)+"\n        "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),(_vm.lastPage !== 0)?_c('div',{staticClass:"text-center",staticStyle:{"padding":"15px 0"}},[_c('v-pagination',{attrs:{"color":"#eabd75","length":_vm.lastPage,"total-visible":8},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }