<template>
    <nav class="front">
    <v-toolbar flat short>
        <v-toolbar-title flat app class="text-uppercase grey--text">
            <span class="display-1 font-weight-bold"  id="RewardProgram">GROF HUB </span>
            <span class="display-1">- {{ currentRouteName }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col cols="1">
            <v-row v-if="loggedIn" align="center" justify="center">
                <v-speed-dial v-model="fab" direction="bottom">
                    <template v-slot:activator>
                        <v-btn class='GrofUSA' color="#eabd75" dark fab hover small v-model="fab">
                            <v-icon v-if="fab">mdi-close</v-icon>
                            <v-icon v-else>mdi-menu</v-icon>
                        </v-btn>
                    </template>
                    <div v-for="Button in buttons" :key="Button.text">
                        <v-tooltip left v-if="hasPermission(Button.text)">
                            <template v-slot:activator="{ on }">
                                <router-link :to="Button.path" replace>
                                    <v-btn fab dark small color="#eabd75" v-on="on" >
                                        <v-icon>{{ Button.icon }}</v-icon>
                                    </v-btn>
                                </router-link>
                            </template>
                            <span>
                                {{ Button.text }}
                            </span>
                        </v-tooltip>
                    </div>

                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <router-link  :to="'/#'"  replace>
                                <v-btn @click="logout" fab dark small color="#eabd75" v-on="on" >
                                    <v-icon>mdi-logout</v-icon>
                                </v-btn>
                            </router-link>
                        </template>
                        <span>
                            Logout
                        </span>
                    </v-tooltip>
                </v-speed-dial>
            </v-row>
        </v-col>
    </v-toolbar>
    </nav>
</template>

<script>
    export default {
        name: "Navbar",

        data: () => ({
            fab: false,
            tooltips: false,
            tooltipsDisabled: true,
            permission: JSON.parse(localStorage.getItem('access_permissions')),
            buttons:[
                {text: 'Home', icon: 'mdi-home', path:'/'},
                {text: 'Personal Users', icon: 'mdi-account', path:'/personals'},
                {text: 'Professional Users', icon: 'mdi-domain', path:'/users'},
                {text: 'Benefits', icon: 'mdi-trophy-award', path:'/benefits'},
                {text: 'Brands', icon: 'mdi-shield-outline', path:'/brands'},
                {text: 'Plans', icon: 'mdi-format-list-bulleted-square', path:'/plans'},
                {text: 'Products', icon: 'mdi-alpha-p-circle-outline', path:'/products'},
                {text: 'Returns', icon: 'mdi-keyboard-return', path:'/returns'},
                {text: 'Price Categories', icon: 'mdi-tag-text-outline', path:'/pricecategories'},
                {text: 'Transactions', icon: 'mdi-tag', path:'/transactions'},
                {text: 'Redeeming Sales', icon: 'mdi-tag-minus', path:'/redeeming'},
                {text: 'Configuration', icon: 'mdi-cogs', path:'/configuration'},
            ],

        }),

        computed: {
            currentRouteName() {
                return this.$route.name;
            },

            loggedIn() {
                return this.$store.getters.loggedIn
            }

        },

        methods:{
            logout(){
                this.$store
                .dispatch("destroyToken")
                .then(response => {
                    this.$router.push({ name: "Login" });
                })
            },

            hasPermission(property){
                let permission = JSON.parse(localStorage.getItem('access_permissions'))
                if (permission){
                    var index = this.permission.childs.findIndex(x => x.resource === property);
                    if (index !==-1){
                        return this.permission.childs[index].access
                    }else{
                        return true
                    }
                }else{
                    return false
                }

            },
        }
    }
</script>

<style scoped>
    .front{
        position: relative;
        z-index: 99;
    }
    .v-btn--floating {
        position: relative;
    }
</style>
