<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <div>
                    <v-btn fab dark small color="#eabd75" @click.stop="searchNewInvoices">
                        <v-icon>mdi-cached</v-icon>
                    </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4" >
                    <v-text-field class="my-0 pt-0"
                            v-model="search"
                            label="Search"
                            single-line
                            hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers"
                      :items="sales"
                      item-key="id"
                      :search="search"
                      :sort-by="['date']"
                      :sort-desc="[true]"
                      @click:row="goToDetails"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait">
            <template v-slot:item.shop.name="{item, header, value}">
                {{ shopName(value) }}
            </template>
            <template v-slot:item.status="{item, header, value}">
                {{ capitalizeLetter(value) }}
            </template>
            <template v-slot:item.stage="{item, header, value}">
                {{ capitalizeLetter(value) }}
            </template>
            <template v-slot:item.date="{item, header, value}">
                <date-component :registration="value"></date-component>
            </template>
        </v-data-table>
        <v-dialog
            v-model="loadingDialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <v-img
                    src = 'https://cdn.shopify.com/s/files/1/0306/7215/8765/t/12/assets/loadding.svg?v=8868230531791137616'
                    height="350px"
                    contain
                    ></v-img>
                    <v-col cols="12" class="d-flex justify-center">
                        <span class="h4">Please stand by</span>
                    </v-col>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import DateComponent from "../../components/DateComponent";

    export default {
        name: "RedeemingSales",
        components: {DateComponent},

        data() {
            return {
                search: '',
                headers: [
                    {
                        text: 'Customer',
                        align: 'start',
                        value: 'user.name',
                    },
                    {text: 'Number', value: 'number'},
                    {text: 'Date', value: 'date'},
                    {text: 'Shop', value: 'shop.name'},
                    {text: 'Status', value: 'status'},
                    {text: 'Stage', value: 'stage'},
                    {text: 'Sub total', value: 'sub_total'},
                    {text: 'Discount total', value: 'discount_total'},
                    {text: 'Total used points', value: 'total_used_points'},
                    {text: 'Total earned points', value: 'total_earned_points'},
                    {text: 'Tax rate', value: 'tax_rate'},
                    {text: 'Total', value: 'total'},
                ],
                sales: [],
                loading: true,
                loadingDialog: false,
            }
        },

        computed: {
        },

        mounted() {
            this.getSales()
        },
        methods:{
            getSales(){
                axios.get('/api/sales').then(res => {
                    this.loading = false;
                    this.sales = res.data.invoices;
                });
            },
            goToDetails(item){
                this.$router.push(`/redeeming/${item.id}`)
            },
            capitalizeLetter(value){
                var string = value;
                return string[0].toUpperCase() + string.slice(1).toLowerCase();
            },
            shopName(val){
                if (val.includes('GROF USA')){
                    return 'GROF USA'
                }else if (val.includes('GROF MD')) {
                    return 'GROF MD'
                } else if (val.includes('VBG MD')) {
                    return 'VBG MD'
                } else {
                    return val
                }
            },
            searchNewInvoices(){
                this.loadingDialog = true;
                axios.get('/api/newsales').then(res => {
                    this.getSales();
                    this.loadingDialog = false;
                });
            }
        }

    };
</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }

    .container{
        max-width: 100vw;
    }

</style>
