<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                    <v-text-field
                            v-model="search"
                            label="Search"
                            single-line
                            hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers"
                      :items="transactions"
                      item-key="name"
                      :search="search"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait">
            <template v-slot:item.created_at="{item, header, value}">
                <date-component :registration="value"></date-component>
            </template>
            <template v-slot:item.points="{item, value}">
                <span v-if="item.operation_type == 1">{{ item.points }}</span>
                <span v-else>0 </span>
            </template>
            <template v-slot:item.operation_type="{item, value}">
                <span v-if="item.operation_type == 0">{{ item.points }}</span>
                <span v-else>0 </span>
            </template>
        </v-data-table>
    </v-app>
</template>

<script>
    import DateComponent from "../../components/DateComponent";

    export default {
        name: "TransactionsByShop",
        components: {DateComponent},

        data() {
            return {
                search: '',
                headers: [
                    {
                        text: 'Customer',
                        align: 'start',
                        value: 'user.name',
                    },
                    {text: 'Role', value: 'role'},
                    {text: 'Benefit', value: 'benefit_plan.benefit.name'},
                    {text: 'Benefit Type', value: 'benefit_plan.benefit.benefit_type.name'},
                    {text: 'Points generated', value: 'points'},
                    {text: 'Points redeemed', value: 'operation_type'},
                    {text: 'Date', value: 'created_at'}
                ],
                transactions: [],
                loading: true,
                shopId: -1,
            }
        },

        computed: {
        },

        mounted() {

            if(this.$route.params.id) {
                this.shopId = this.$route.params.id;
                axios.get('/api/transactionsByShop/' + this.shopId).then(res => {
                    this.loading = false;
                    this.transactions = res.data.transactions;
                });
            }
        },

    };
</script>

<style scoped>
    ::v-deep tbody td{
        cursor: pointer;
    }

    ::v-deep tbody td td.non-clickable{
        cursor: auto;
    }
</style>