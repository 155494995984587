<template>
    <v-app>
        <navbar></navbar>
        <v-content class="pl-4 pr-4">
            <router-view></router-view>
        </v-content>
    </v-app>
</template>

<script>
    import navbar from "../components/Navbar";

    export default {
        name: "app",
        components: {navbar},

    }
</script>

<style scoped>

</style>
