<template>
    <span>
        {{dateFormatted.getDate()}}/{{dateFormatted.getMonth()+1}}/{{dateFormatted.getFullYear()}}
    </span>
</template>

<script>
    export default {
        name: "DateComponent",
        props: ['registration'],

        computed: {
            dateFormatted: function (){
                var formated = new Date(this.registration);
                return formated
            }
        }
    }
</script>

<style scoped>

</style>
