<template>
    <v-app>
        <v-container class="mr-0 ml-0">
            <v-row no-gutters justify="center">
                <v-btn-toggle v-model="toggle_exclusive">
                    <v-btn v-for="button in buttons" :key="button.text">
                        {{button.text}}
                    </v-btn> 
                </v-btn-toggle>
            </v-row>
        </v-container>
        <div class='d-flex justify-end'>
            <v-btn v-if="toggle_exclusive==0" @click="newUser()">
                New User
            </v-btn> 
            <v-btn v-if="toggle_exclusive==1" @click="newRole()">
                New Role
            </v-btn>  
        </div>
        <div v-if="toggle_exclusive==2" class='d-flex justify-center'>
            <v-col cols="12" sm="6" md="6">
                <v-select
                    :items="roles"
                    v-model="role_permission"
                    label="Role"
                    @change="loadPermissions"
                >
                </v-select>
            </v-col>
        </div>
        <v-data-table :headers="user_headers"
                      :items="users"
                      item-key="id"
                      :sort-by="['created_at']"
                      :sort-desc="[true]"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      v-if="toggle_exclusive==0? true: false"
                      >
            <template v-slot:item.created_at="{item, header, value}">
                <date-component :registration="value"></date-component>
            </template>
            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon small class="mr-2" v-on:click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small v-on:click="openNotification(item)">
                        mdi-delete
                    </v-icon>
                </td>
            </template>
        </v-data-table>
        <v-data-table 
            :headers="roles_headers"
            :items="roles_data"
            :loading="loading_roles"
            v-if="toggle_exclusive==1? true: false">
            <template v-slot:item.created_at="{item, header, value}">
                <date-component :registration="value"></date-component>
            </template>
            <template v-slot:item.actions="{ item }">
                <td @click.stop class="non-clickable">
                    <v-icon small class="mr-2" v-on:click="editRole(item)">
                        mdi-pencil
                    </v-icon>
                </td>
            </template>
        </v-data-table>
        <v-dialog v-model="notification" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline font-weight-bold text-uppercase grey--text">Alert</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-col cols="12">
                            <span class="subtitle-1">Are you sure you want to delete this user?</span>
                        </v-col>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteitem">Accept</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card ref="form" >
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field ref="name"
                                                v-model="editedItem.name"
                                                label="Name"
                                                :rules="[() => !!editedItem.name|| 'A name must be provided']"
                                                required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field ref="email" :rules="emailRules" v-model="editedItem.email" label="Email"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-select
                                    ref="role_id"
                                    :items="roles"
                                    v-model="editedItem.role_id"
                                    label="Rol"
                                    :rules="[(v) => !!v || 'Debe especificar un Rol']"
                                    required
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field ref="password"
                                            v-model="editedItem.password"
                                            label="Password"
                                            :type="'password'"
                                            :rules="editedIndex>-1 ? [] : [() => !!editedItem.password|| 'Debe proporcionar una contraseña']" ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="Roledialog" max-width="500px">
            <v-card ref="role_form" >
                <v-card-title>
                    <span class="headline">{{ formTitleRole }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field ref="role_name"
                                                v-model="editedRoleItem.name"
                                                label="Name"
                                                :rules="[() => !!editedRoleItem.name|| 'A name must be provided']"
                                                required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field ref="role_description"
                                            v-model="editedRoleItem.description"
                                            label="description"
                                            :rules="[() => !!editedRoleItem.description|| 'A description must be provided']" >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeRole">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveRole">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card class="my-3" elevation="3" v-if='toggle_exclusive==2 && role_permission!=null'>
                <v-card-title>Permissions</v-card-title>
                <v-data-table
                    :headers="permissions_headers"
                    :items="modules.childs"
                    hide-default-footer
                    item-key="resource"
                    :loading="loading"
                    :items-per-page="modules.childs.length"
                    loading-text="Loading...">
                    <template v-slot:item.access="{ item }">
                        <v-simple-checkbox
                            v-model="item.access"
                        ></v-simple-checkbox>
                    </template>
                </v-data-table>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue lighten-2"
                        text
                        @click="savePermission"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-app>
</template>

<script>

import DateComponent from "../../components/DateComponent";

export default {
    name: "Configuration",
    components: {DateComponent},
    data () {
      return {
        roles_data: [],
        toggle_exclusive: 0,
        loading: true,
        dialog:false,
        Roledialog: false,
        editedIndex: -1,
        editedRoleIndex: -1,
        notification:false,
        users: [],
        
        roles: [],
        
        formHasErrors: false,
        roleFormHasErrors: false,
        role_permission:null,
        permissions_headers:[
            {text: 'Module', value: 'resource'},
            {text: 'Access', value: 'access'}
        ],
        defaulPermissions:{
            childs:[
                {resource: 'Home', access: false, icon: 'mdi-home', path:'/'},
                {resource: 'Personal Users', access: false, icon: 'mdi-account', path:'/personals'},
                {resource: 'Professional Users', access: false, icon: 'mdi-domain', path:'/users'},
                {resource: 'Benefits', access: false,icon: 'mdi-trophy-award', path:'/benefits'},
                {resource: 'Brands', access: false, icon: 'mdi-shield-outline', path:'/brands'},
                {resource: 'Plans', access: false, icon: 'mdi-format-list-bulleted-square', path:'/plans'},
                {resource: 'Products', access: false, icon: 'mdi-alpha-p-circle-outline', path:'/products'},
                {resource: 'Price Categories',access: false, icon: 'mdi-tag-text-outline', path:'/pricecategories'},
                {resource: 'Transactions', access: false, icon: 'mdi-tag', path:'/transactions'},
                {resource: 'Redeeming Sales', access: false, icon: 'mdi-tag-minus', path:'/redeeming'},
                {resource: 'Configuration', access: false, icon: 'mdi-cogs', path:'/configuration'},
            ]
        },
        
        loading_roles:true,
       
        modules:{},
        user_headers:[
            {text: 'Name', value: 'name'},
            {text: 'Email', value: 'email'},
            {text: 'Role', value: 'roles[0].description'},
            {text: 'Creation Date', value: 'created_at'},
            {text: 'Actions', value: 'actions', sortable: false},
        ],
        roles_headers:[
            
            {text: 'description', value: 'name'},
            {text: 'Creation Date', value: 'created_at'},
            {text: 'Actions', value: 'actions', sortable: false},
        ],
        buttons:[
             {text: 'Users', selected: true, path:'/'},
             {text: 'Roles', selected: false, path:'/'},
             {text: 'Permissions', selected: false, path:'/'}
        ],
        editedItem: {
            name: "",
            email: "",
            role_id: null,
            password:''
        },
        editedRoleItem: {
            name: "",
            email: "",
            role_id: null,
            password:''
        },
        defaultRoleItem: {
            name: "",
            email: "",
            role_id: null,
            password:''
        },
        defaultItem: {
            name: "",
            email: "",
            role_id: null,
            password:''
        },
        emailRules: [
            v => !!(v) || 'Provide a valid email address',
            v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'e-mail invalido'
        ],
      }
    },
    mounted(){
        this.getUsers();
        this.getRoles();
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New User" : "Edit User";
        },
        formTitleRole() {
            return this.editedRoleIndex === -1 ? "New Role" : "Edit Role";
        },
    },
    methods:{
        getUsers(){
            this.users= [];
            this.loading=true;
            axios.get('/api/workers').then(res => {
                this.loading = false;
                this.users = res.data.workers;
            });
        },

        getRoles(){
            
            this.loading_roles = true;
            axios.get('/api/internal_roles').then(res => {
                this.loading_roles = false;
                for (var i = 0; i < res.data.roles.length; i++) {
                    this.roles_data.push({ name:res.data.roles[i].name, id:res.data.roles[i].id, created_at:res.data.roles[i].created_at, permissions: res.data.roles[i].permissions ? JSON.parse(res.data.roles[i].permissions): JSON.parse(JSON.stringify(this.defaulPermissions))});
                }
                this.roles = res.data.internal_roles;
            });
        },

        newUser(){
            this.dialog=true;
        },

        openNotification: function (item) {
            this.notification = true;
            this.editedIndex = this.users.indexOf(item);
        },

        editItem: function (item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem['role_id'] = item.roles[0].id;
            this.dialog = true;
        },

        deleteitem(){
            axios.delete('/api/internal_users/' + this.users[this.editedIndex].id
            ).then(res => {
                this.users=[];
                this.loading=true;
                this.getUsers()
            }).catch(error=> {
                
            });
            this.closeDelete();
        },

        form($request = false) {
            var form ={}
            if (this.editedIndex){
                form.name=this.editedItem.name;
                form.email=this.editedItem.email;              
            }else{
                form.name = this.editedItem.name;
                form.email = this.editedItem.email;
                form.password = this.editedItem.password;
            }
            
            if ($request){
                form.role_id = this.editedItem.role_id
                form.password = this.editedItem.password
            }

            return form
        },

        roleForm($request = false){
            let form = {
                'role_name':this.editedRoleItem.name,
                'role_description': this.editedRoleItem.description
            }
            return form
        },

        save(){
            this.formHasErrors = false;

            Object.keys(this.form()).forEach(f => {
                if (!this.form()[f]) this.formHasErrors = true;
                this.$refs[f].validate(true);
            });

            if (!this.formHasErrors){
                if (this.editedIndex > -1) {
                    axios.put('/api/internal_users/' + this.users[this.editedIndex].id,
                        this.form(true)
                    ).then(res => {
                        this.users=[];
                        this.loading=true;
                        this.getUsers()
                    }).catch(error=> {
                        if (error.response && error.response.status === 422)
                        {
                            let errors = error.response.data.errors;
                        }
                    });
                }else{
                    axios.post('/api/internal_users',
                        this.form(true)
                    ).then(res => {
                        this.users=[];
                        this.loading=true;
                        this.getUsers()
                    }).catch(error=>{
                        
                    });
                }
                this.close()
            }
        },
        
        resetForm () {
            this.formHasErrors = false;

            Object.keys(this.form()).forEach(f => {
                this.$refs[f].reset()
            })
        },

        resetRoleForm () {
            this.roleFormHasErrors = false;

            Object.keys(this.roleForm()).forEach(f => {
                this.$refs[f].reset()
            })
        },

        close(){
            this.dialog = false;
            this.notification= false;
            this.resetForm();
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete(){
            this.notification= false;
            this.editedIndex = -1;
        },

        newRole(){
            
            this.Roledialog = true
        },

        editRole(item) {
            this.editedRoleIndex = this.roles_data.indexOf(item);
            this.editedRoleItem = Object.assign({}, item);
            this.Roledialog= true;
        },

        saveRole(){
          this.roles_data= []; 
            this.roleFormHasErrors = false;

            Object.keys(this.roleForm()).forEach(f => {
                if (!this.roleForm()[f]) this.roleFormHasErrors = true;
                this.$refs[f].validate(true);
            });
            if (!this.roleFormHasErrors){
                if (this.editedRoleIndex > -1) {
                    axios.put('/api/internal_roles/' + this.roles_data[this.editedRoleIndex].id,
                        this.roleForm(true)
                    ).then(res => {
                        this.getRoles()
                    }).catch(error=> {
                        if (error.response && error.response.status === 422)
                        {
                            let errors = error.response.data.errors;
                        }
                    });
                }else{
                    axios.post('/api/internal_roles',
                        this.roleForm(true)
                    ).then(res => {
                        this.getRoles()
                    }).catch(error=>{
                        
                    });
                }
                this.closeRole()
            }
            
        },

        closeRole(){
            this.Roledialog = false;
            this.resetRoleForm();
            this.$nextTick(() => {
                this.editedRoleIndex = -1;
                this.editedRoleItem = Object.assign({}, this.defaultRoleItem);})
        },

        loadPermissions(){
            let index= this.roles_data.findIndex(role=>role.id==this.role_permission);
            let permissions= this.roles_data[index].permissions;
            if (!permissions){
                this.modules = this.defaulPermissions
            }else{
                this.modules = permissions
            }
        },
        
        async savePermission(){
            let data = JSON.stringify(this.modules);
            axios.post('/api/assignpermissions',
                {
                    'id': this.role_permission,
                    'permissions': data
                }
                ,{
                headers: {
                    Authorization: 'Bearer ' + this.token,
                }
            }).then(response => {
                console.log(response.data)
            });
        },
    }
}
</script>

<style>

</style>