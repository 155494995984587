var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{staticClass:"mr-0 ml-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.transactions,"item-key":"name","search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_c('date-component',{attrs:{"registration":value}})]}},{key:"item.points",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.operation_type == 1)?_c('span',[_vm._v(_vm._s(item.points))]):_c('span',[_vm._v("0 ")])]}},{key:"item.operation_type",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.operation_type == 0)?_c('span',[_vm._v(_vm._s(item.points))]):_c('span',[_vm._v("0 ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }