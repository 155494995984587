import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    role: localStorage.getItem('role') || null,
    permissions: localStorage.getItem('access_permissions') || null,
    snackbar: {
      visible: false,
      color: "success",
      text: null,
      timeout: 5000,
      multiline: false,
      top:'top',
      right: 'right'
    },
  },

  getters: {
    loggedIn(state) {
      return state.token !== null
    },
    permissions(state){
      return state.permissions
    }
  },

  mutations: {
    retrieveToken(state, token) {
      state.token = token
    },

    destroyToken(state) {
      state.token = null
      state.role = null
      state.permissions = null
    },

    retrievePermissions(state, permissions){
      state.permissions = permissions
    },
    destroyPermissions(state) {
        state.permissions = null
    },

    alert(state, payload) {
      state.snackbar.text = payload.text;
      state.snackbar.multiline = payload.text.length > 50;

      // Si es mas de 50 caracteres definirlo como multilinea
      if (payload.multiline) {
          state.snackbar.multiline = payload.multiline;
      }

      // Color para mensajes de error o éxito
      if (payload.color) {
          state.snackbar.color = payload.color;
      }

      // Tiempo de duración
      if (payload.timeout) {
          state.snackbar.timeout = payload.timeout;
      }
      state.snackbar.visible = true;
    },

    closeAlert(state) {
        state.snackbar.visible = false;
        state.snackbar.multiline = false;
        state.snackbar.text = null;
    },
  },
  actions: {
    retrieveToken(context, credentials) {

      return new Promise((resolve, reject) => {
        axios.post('/api/weblogin', {
          email: credentials.email,
          password: credentials.password
        })
          .then(response => {
            if (response.status === 200){
              const token = response.data.access_token
              var permissions = response.data.permissions
              const role = response.data.role
              localStorage.setItem('access_token', token)
              localStorage.setItem('access_permissions', permissions)
              localStorage.setItem('role', role)
              context.commit('retrieveToken', token)
              context.commit('retrievePermissions', permissions)
              resolve(response)
            }
          })
          .catch(error => {
            //console.log(error.response.data)
            reject(error)
          })
      })

    },

    destroyToken(context) {

      if (context.getters.loggedIn){

        return new Promise((resolve, reject) => {
          axios.get('/api/logout', {
              headers: { Authorization: "Bearer " + context.state.token, }
            })
            .then(response => {
              //console.log(response)
              localStorage.removeItem('access_token')
              localStorage.removeItem('access_permissions')
              localStorage.removeItem('role')
              context.commit('destroyToken')

              resolve(response)
            })
            .catch(error => {
              //console.log(error)
              localStorage.removeItem('access_token')
              localStorage.removeItem('role')
              context.commit('destroyToken')

              reject(error)
            })
        })

      }
    }
  }
})

export default store
